import { Component, OnInit } from "@angular/core";
import { LogirastreosService } from "../services/logirastreos.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import * as moment from "moment";
import Swal from "sweetalert2";
import { VehiculosService } from "src/app/services/vehiculos.service";

@Component({
  selector: "app-logirastreos",
  templateUrl: "./logirastreos.component.html",
  styleUrls: ["./logirastreos.component.css"],
})
export class LogirastreosComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  form: FormGroup;
  _clave: string = "528ab867badacf5c8182456a35f72ca5";
  data_rpt_planillas = [];
  _tituloReporte = "REPORTE DE PLANILLAS LOGITRASTREOS";
  desde: string = "";
  hasta: string = "";
  xbus: string = "";
  _placa: string = "";

  constructor(
    private fb: FormBuilder,
    private _serviceLogirasteos: LogirastreosService,
    private _serviceVehiculos: VehiculosService
  ) {
    this.form = this.fb.group({
      clave: [this._clave],
      placa: [""],
      fecha_desde: [new Date(), Validators.required],
      fecha_hasta: [new Date(), Validators.required],
      numero: ["", Validators.required],
    });
  }

  ngOnInit() {}

  get fdata() {
    return this.form.controls;
  }

  consultar() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    // this.desde = moment(this.form.controls.fecha.value).format("YYYY-MM-DD");
    this.form.controls.fecha_desde.patchValue(
      moment(this.form.controls.fecha_desde.value).format("YYYY-MM-DD")
    );
    this.form.controls.fecha_hasta.patchValue(
      moment(this.form.controls.fecha_hasta.value).format("YYYY-MM-DD")
    );

    this.xbus = this.form.controls.numero.value;
    this._serviceLogirasteos.EventDatosLogirasteos$.emit([]);
    this._serviceLogirasteos.FechaDesde$.emit(this.desde);
    this._serviceLogirasteos.FechaHasta$.emit(this.hasta);
    this._serviceLogirasteos.bus$.emit(this.xbus);

    let _numero = "";

    if (this.form.controls.numero.value === "") {
      Swal.fire("Precaución", "Número de Vehículo no válido...", "warning");
      this.blockUI.stop();
      return false;
    }
    _numero = this.form.controls.numero.value;
    this._serviceVehiculos.getbynumero(_numero).subscribe((resp: any) => {
      if (resp.data[0].placa) {
        this._placa = resp.data[0].placa;
        this._serviceLogirasteos.placa$.emit(resp.data[0].placa);
        this.form.controls.placa.patchValue(resp.data[0].placa);
        this._serviceLogirasteos
          .get_planillabyplacaAndPeriodo(this.form.value)
          .subscribe((resp: any) => {
            this.data_rpt_planillas = resp.planillas;
            this._serviceLogirasteos.EventDatosLogirasteos$.emit(
              resp.planillas
            );
            this.blockUI.stop();
          });
      }
    });
  }
}
