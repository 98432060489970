import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { PlanillasService } from "../../planillas/services/planillas.service";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import pageSettings from "../../../../config/page-settings";
import { PlanillasLiquidacionService } from "../../planillas-liquidacion/services/planillas-liquidacion.service";
import { NgbTimepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.reducers";
import { cargarParametroAnioActual } from "../../planilla-parametros/store/actions";
import { PlanillaParametro } from "src/app/models/planilla-parametro.model";
import { cargarSanciones } from "../../sanciones/store/actions";
import { Sancion } from "src/app/models/sanciones.model";
import { PlanillasSancionesService } from "../../planillas-sanciones/services/planillas_sanciones.service";
import Swal from "sweetalert2";
import { MensajesToastrService } from "src/app/mensajes/toastr-service";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDatepickerInputEvent,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DespachosService } from "src/app/services/despachos.service";
import { Despacho } from "src/app/models/despachos.model";
import { PlanillaParametrosService } from "../../planilla-parametros/services/planilla-parametros.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-new-edit",
  templateUrl: "./new-edit.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
      mat-form-field {
        width: 100%;
      }
      div.matfila {
        max-width: 70px !important;
      }
      /* div > .mat-form-field-appearance-fill.mat-form-field-flex {
        padding: 5px !important;
      } */
      #content > mat-form-field > div > div.mat-form-field-flex {
        padding: 5px !important;
      }
      .material-icons {
        vertical-align: middle;
      }

      table.tableliquidacion {
        font-size: 16px;
      }
      td.tdtitulo {
        font-weight: 600;
      }

      h4.panel-title.ng-star-inserted {
        font-size: 14px !important;
      }

      .panel.panel-heading.panel-title {
        font-size: 14px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PlNewEditComponent
  implements OnInit, AfterContentChecked, OnDestroy
{
  sub: Subscription = new Subscription();
  sancionesSubs: Subscription = new Subscription();
  planillasSubscription: Subscription = new Subscription();
  cerrarPlanillaSubscription: Subscription = new Subscription();
  subsAll$: Subscription = new Subscription();

  frmBuscar: FormGroup;
  datos_planilla: any = [];
  datos_liquidacion: any = [];
  arraySanciones: Sancion[] = [];
  sanciones_registradas: Sancion[] = [];
  data_despachos: Despacho[] = [];
  fields: any;
  pageSettings = pageSettings;
  indexSelected = 0;
  vr_minuto_retardo: number = 0;
  vr_total_retardo: number = 0;
  ppSubs: Subscription;
  isLoadParametros: boolean = false;
  _frmSanciones: FormGroup;
  loading: boolean = false;
  _total_planilla: number = 0;
  _planilla_id: number = 0;
  _totalSancion: number = 0;
  _fila: number = 1;
  _subtotal_minutos_retardo: number = 0;
  _descuento_minutos_retardo: number = 0;
  _total_minutos_retardo: number = 0;
  _suma_total_minutos: number = 0;
  _parametrosplanilla: PlanillaParametro;
  _ahorro_propietario: number = 0;
  _aporte_cooperativa: number = 0;
  _valor_relevante: number = 0;
  _msgSanciones: string = "";
  _sancionExiste: boolean = false;
  _statusSancionOk: boolean = false;
  _subtotal_sanciones_registradas: number = 0;
  _grantotal_sanciones_registradas: number = 0;
  _totaldescuento_sanciones_registradas: number = 0;
  _valor_minutos_sancionados: number = 0;
  _minutos_sancionados: number = 0;
  _valor_descuento_minutos_sancion: number = 0;
  _valor_abono_ss: number = 0;
  _valor_abono_ss_relevante: number = 0;
  _total_sancion: number = 0;
  _despacholiquidacion_id: number = 0;
  frmLiquidaIsValid: boolean = true;
  frmSancionesIsValid: boolean = true;
  groupedData: any[];
  uniqueRutas: any[];
  list_roles = [];
  isDisabledControl: boolean = false;
  _fliquidacion: Date;
  _totalRetardo: number;

  saveItem: boolean = false;
  differenceInMinutes: number;
  min_retardo: number = 0;
  nruta = "";
  nr = "";
  minDate: Date;
  maxDate: Date;
  _userSession: any = "0";
  _userSessionRoles: any = "0";
  _retardoString = "";
  time: string = "";

  frmTbPlanilla: FormGroup;
  frmTbPlanillaLiquidacion = this.fb.group({
    frmArrayLugares: this.fb.array([]),
  });

  frmSanciones = this.fb.group({
    frmArraySanciones: this.fb.array([]),
  });

  constructor(
    private route: ActivatedRoute,
    private _servicePlanillas: PlanillasService,
    private _serviceLiquidacion: PlanillasLiquidacionService,
    private _servicePlanillaSanciones: PlanillasSancionesService,
    private _serviceDespachos: DespachosService,
    private _servicePlanillaParametros: PlanillaParametrosService,
    private fb: FormBuilder,
    private store: Store<AppState>,
    configTimePicker: NgbTimepickerConfig,
    private _serviceToastr: MensajesToastrService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.pageSettings.pageWithoutSidebar = false;
    this.pageSettings.pageTopMenu = false;
    configTimePicker.spinners = false;

    /* const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 1, 0, 1); */

    this.store.dispatch(cargarParametroAnioActual({ id: "2025" }));
  }

  ngOnInit() {
    const fdesde = this.route.snapshot.queryParams["fdesde"];
    const fhasta = this.route.snapshot.queryParams["fhasta"];

    this._serviceDespachos.get_listar("1", "100").subscribe((resp: any) => {
      this.data_despachos = resp.data;
      // this.form.controls["despacho_id"].patchValue(2);
    });

    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
    this._userSessionRoles = JSON.parse(sessionStorage.getItem("roles"));
    const expectedRoles = ["liquidacion_parcial"];

    const session_roles = sessionStorage.getItem("roles");
    let arrayRoles = JSON.parse(session_roles);

    arrayRoles.map((r) => {
      this.list_roles.push(r.role_name);
    });

    if (this.list_roles.some((r) => expectedRoles.includes(r))) {
      this.isDisabledControl = true;
    }
    this.datos_iniciales();
    // });
  }

  datos_iniciales() {
    console.log("datos_iniciales()");
    this._total_planilla = 0;
    this.datos_planilla = [];
    this.datos_liquidacion = [];
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
    this.listar_sanciones();
    this.sub = this.route.params.subscribe((params) => {
      this.listar_sanciones_registradas(params.planilla_id);
      //*********Redux******* */

      /*********Redux******* */

      this._servicePlanillas.getById(params.planilla_id).subscribe((resp) => {
        this.datos_planilla = resp;

        this._planilla_id = this.datos_planilla.planilla_id;
        this.vr_minuto_retardo = this.datos_planilla.valor_minuto_retardo;
        this._descuento_minutos_retardo =
          this.datos_planilla.descuento_minutos_retardo;
        this._fliquidacion = this.datos_planilla.fecha_liquidacion;
        this._despacholiquidacion_id =
          this.datos_planilla.despacholiquidacion_id;
        this.frmTbPlanilla = this.fb.group({
          planilla_id: [this.datos_planilla.planilla_id],
          despacholiquidacion_id: [this.datos_planilla.despacholiquidacion_id],
          fecha_liquidacion: [
            moment(this.datos_planilla.fecha_liquidacion),
            Validators.required,
          ],
          subtotal_minutos_retardo: [
            this._subtotal_minutos_retardo,
            Validators.required,
          ],
          descuento_minutos_retardo: [
            this.datos_planilla.descuento_minutos_retardo,
            Validators.required,
          ],
          valor_planilla: [
            this.datos_planilla.valor_planilla,
            Validators.required,
          ],
          valor_minuto_retardo: [
            this.datos_planilla.valor_minuto_retardo,
            Validators.required,
          ],
          total_minutos_sancion: [
            this.datos_planilla.total_minutos_sancion,
            Validators.required,
          ],
          valor_minutos_sancion: [
            this.datos_planilla.valor_minutos_sancion,
            Validators.required,
          ],
          otras_sanciones: [
            this.datos_planilla.otras_sanciones,
            Validators.required,
          ],

          otros: [this.datos_planilla.otros, Validators.required],

          valor_ss_conductor: [
            this.datos_planilla.valor_ss_conductor,
            Validators.required,
          ],
          valor_ahorro_propietario: [
            this.datos_planilla.valor_ahorro_propietario,
            Validators.required,
          ],
          abono_credito_cooperativa: [
            this.datos_planilla.abono_credito_cooperativa,
            Validators.required,
          ],
          valor_relevante: [
            this.datos_planilla.valor_relevante,
            Validators.required,
          ],
          valor_gps: [this.datos_planilla.valor_gps, Validators.required],
          valor_vias: [this.datos_planilla.valor_vias, Validators.required],
          valor_fondo_contingencia: [
            this.datos_planilla.valor_fondo_contingencia,
            Validators.required,
          ],
          valor_bioseguridad: [
            this.datos_planilla.valor_bioseguridad,
            Validators.required,
          ],
          valor_aporte_cooperativa: [
            this.datos_planilla.valor_aporte_cooperativa,
            Validators.required,
          ],
          valor_descuento_minutos_sancion: [
            this.datos_planilla.valor_descuento_minutos_sancion,
            Validators.required,
          ],
          valor_abono_ss: [
            this.datos_planilla.valor_abono_ss,
            Validators.required,
          ],
          valor_abono_ss_relevante: [
            this.datos_planilla.valor_abono_ss_relevante,
            Validators.required,
          ],
          observacion: [this.datos_planilla.observacion],
          observacion_otras_sanciones: [
            this.datos_planilla.observacion_otras_sanciones,
          ],

          observacion_otros: [this.datos_planilla.observacion_otros],

          _total_minutos_retardo: [0],
          _total_sancion: [this.datos_planilla.total_byminutos_sancion],

          _suma_total_minutos: [
            this.datos_planilla.subtotal_minutos_retardo -
              this._minutos_sancionados *
                this.datos_planilla.valor_minuto_retardo,
          ],
        });
        setTimeout(() => {
          this._ahorro_propietario =
            this.datos_planilla.valor_ahorro_propietario;
          this._aporte_cooperativa =
            this.datos_planilla.abono_credito_cooperativa;

          this._valor_descuento_minutos_sancion =
            this.datos_planilla.valor_descuento_minutos_sancion;
          this._valor_abono_ss = this.datos_planilla.valor_abono_ss;
          this._valor_abono_ss_relevante =
            this.datos_planilla.valor_abono_ss_relevante;
          this._total_sancion = this.datos_planilla.total_byminutos_sancion;
          const fplanilla = new Date(this.datos_planilla.fecha_planilla);

          this.minDate = new Date(
            fplanilla.getFullYear(),
            fplanilla.getMonth(),
            fplanilla.getDate() + 1
          );
          this.generar_liquidacion("Sin Redux");
        }, 200);
      });
    });
  }

  generar_liquidacion(msg: string) {
    this._fila = 1;
    this.saveItem = false;
    this._subtotal_minutos_retardo = 0;
    this.datos_liquidacion = [];
    this.min_retardo = 0;
    (
      this.frmTbPlanillaLiquidacion.controls["frmArrayLugares"] as FormArray
    ).clear();
    this.uniqueRutas = [];
    this.subsAll$.add(
      this._serviceLiquidacion
        .getLiquidarPlanilla(this.datos_planilla.planilla_id)
        // .pipe(shareReplay(1))
        .subscribe((resp) => {
          this.datos_liquidacion = resp;

          if (this.datos_liquidacion) {
            if (this.datos_liquidacion.length === 0) {
              Swal.fire(
                "Atención",
                "No se ha generado los datos para la liquidación...",
                "warning"
              );
            } else {
              this.get_rutas_unq();
              (
                this.frmTbPlanillaLiquidacion.controls[
                  "frmArrayLugares"
                ] as FormArray
              ).clear();
              this.min_retardo = 0;
              this.datos_liquidacion.forEach((x) => {
                const dateEstimado = moment(x.tiempo_paso);
                const dateGPS = moment(x.tiempo_registro);
                const minDif = dateGPS.diff(dateEstimado, "minutes");
                // this.nr = x.group_ruta_horasalida;

                if (x.tiempo_registro > x.tiempo_paso) {
                  //Contabilizar solo minutos de retraso
                  this.min_retardo += minDif;
                } else {
                }
                x.retardo = x.retardo.substring(0, x.retardo.length - 3); //Para suprimir los segundos de 00:05:00 o -00:05:00
                x.gps = x.gps.substring(0, 5);

                if (
                  x.minutos_retardo_sancion > 0 &&
                  minDif > x.minutos_retardo_sancion
                ) {
                  x.minutos_sancionados = dateGPS.diff(dateEstimado, "minutes");
                  x.valor_minutos_sancionados =
                    x.minutos_sancionados * x.valor_minuto_sancion;
                } else {
                  x.minutos_sancionados = 0;
                  x.valor_minutos_sancionados = 0;
                }
                this._subtotal_minutos_retardo = this.min_retardo;

                if (this.nr !== x.group_ruta_horasalida) {
                  this.nruta = x.group_ruta_horasalida;
                } else {
                  this.nruta = "";
                }
                this.addItem(x, this.nruta);
              });

              this.frmTbPlanilla.controls["valor_relevante"].patchValue(
                this.datos_planilla.valor_relevante
              );
              this.frmTbPlanilla.controls[
                "valor_ahorro_propietario"
              ].patchValue(this.datos_planilla.valor_ahorro_propietario);
              this.frmTbPlanilla.controls[
                "abono_credito_cooperativa"
              ].patchValue(this.datos_planilla.abono_credito_cooperativa);

              this.frmTbPlanilla.controls[
                "valor_descuento_minutos_sancion"
              ].patchValue(this.datos_planilla.valor_descuento_minutos_sancion);
              this.frmTbPlanilla.controls["valor_abono_ss"].patchValue(
                this.datos_planilla.valor_abono_ss
              );
              this.frmTbPlanilla.controls[
                "valor_abono_ss_relevante"
              ].patchValue(this.datos_planilla.valor_abono_ss_relevante);
              if (this.datos_planilla.relevante_id === -1) {
                this._valor_relevante = 0;
              } else {
                this.frmTbPlanilla.controls["valor_relevante"].patchValue(
                  this.datos_planilla.valor_ss_conductor
                );
              }
              setTimeout(() => {
                this.getValorTotalMinutosSancionados();
                this.getTotalMinutosSancionados();
                this._totalRetardo = this.getTotalRetardo();

                this.calcularPlanilla();
              }, 200);
            }
          }
        })
    );
  }

  addItem(model: any, nruta: string) {
    const frm = this.fb.group({
      fila: [this._fila + "."],
      planilla_id: [model.planilla_id],
      planillaliquidacion_id: [model.planillaliquidacion_id],
      lugarcontrol_id: [model.lugarcontrol_id],
      minuto: [model.minuto],
      minutos_retardo_sancion: [model.minutos_retardo_sancion],
      valor_minuto_sancion: [model.valor_minuto_sancion],
      minutos_sancionados: [model.minutos_sancionados],
      valor_minutos_sancionados: [model.valor_minutos_sancionados],
      despacho_id: [model.despacho_id],

      tiempo_paso: [
        moment(model.tiempo_paso).format("YYYY-MM-DD HH:mm"),
        Validators.required,
      ],

      _lugarcontrol_nombre: [model.lugarcontrol_nombre],
      _tiempo_paso: [moment(model.tiempo_paso).format("HH:mm")],
      gps: [{ value: model.gps, disabled: this.isDisabledControl }],
      _gps: [{ hour: model.gps_hora, minute: model.gps_minuto }],
      retardo: [model.retardo],
      nota: [model.nota],
      _ruta_horasalida: [nruta],
    });
    this.lugarescontrol.push(frm);
    this._fila++;
    this.nr = model.group_ruta_horasalida;
  }

  setControlStatesBasedOnRole() {
    if (this._userSessionRoles === "liquidacion_parcial") {
      this.frmTbPlanillaLiquidacion.get("gps").disable();
    }
    // Puedes añadir más lógica según los roles y controles
  }

  Buscar() {}

  async submitSanciones(frm: any) {
    this.saveItem = false;
    this._sancionExiste = false;
    this._statusSancionOk = false;
    for (
      let index = 0;
      index < this.frmSanciones.get("frmArraySanciones")["controls"].length;
      index++
    ) {
      await this._servicePlanillaSanciones
        .create(
          (<FormArray>this.frmSanciones.get("frmArraySanciones")).at(index)
            .value
        )
        .subscribe(async (resp: any) => {
          if (resp.status === "ok") {
            this._sancionExiste = false;
            this._statusSancionOk = true;
            this._msgSanciones = "Sanción agregada con éxito..";
          } else {
            this._msgSanciones = resp.msg;
            this._sancionExiste = true;
            this._statusSancionOk = false;
            (<FormArray>this.frmSanciones.get("frmArraySanciones")).removeAt(
              index
            );
          }
          if (
            index >=
            this.frmSanciones.get("frmArraySanciones")["controls"].length - 1
          ) {
            await this.listar_sanciones_registradas(this._planilla_id);
            await this.removeAllontrols();
            await this.calcularPlanilla();
          }
        });
    }
  }

  get fdataLiquidacion() {
    return this.frmTbPlanilla.controls;
  }
  get lugarescontrol() {
    return this.frmTbPlanillaLiquidacion.controls[
      "frmArrayLugares"
    ] as FormArray;
  }

  get frmdatasanciones() {
    return this.frmSanciones.controls["frmArraySanciones"] as FormArray;
  }

  updItem(i: number) {
    this.saveItem = false;
    this._serviceLiquidacion
      .update(
        (<FormArray>this.frmTbPlanillaLiquidacion.get("frmArrayLugares")).at(i)
          .value.planillaliquidacion_id,
        (<FormArray>this.frmTbPlanillaLiquidacion.get("frmArrayLugares")).at(i)
          .value
      )
      .subscribe((resp: any) => {
        if (resp.status === "ok") {
          this.generar_liquidacion("updItem(i: number)");
          this._serviceToastr.showSuccess(
            "Registro actualizado correctamente..."
          );
        } else {
          this._serviceToastr.showError(resp.msg);
        }
      });
  }

  quitarSancion(i: any, item: any) {
    this.frmSancionesIsValid = true;
    (<FormArray>this.frmSanciones.get("frmArraySanciones")).removeAt(i);
  }

  async deleteSancionById(item: Sancion) {
    this.saveItem = false;

    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html: "<p><b>Sanción:</b> " + item.sancion_descripcion + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._servicePlanillaSanciones
          .getdelete(item.planillasancion_id.toString())
          .subscribe(async (resp: any) => {
            if (resp.status === "ok") {
              await this.listar_sanciones_registradas(item.planilla_id);
              await this.calcularPlanilla();
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }

  onSancionChange(event) {
    console.log("onSancionChange");
    this._sancionExiste = false;
    this.addSancion(
      this.arraySanciones.find((x) => x.sancion_id === event.value)
    );
  }

  addSancion(model: any) {
    this._frmSanciones = this.fb.group({
      planilla_id: [this._planilla_id],
      sancion_id: [model.sancion_id],
      descripcion: [model.descripcion],
      /* valor_sancion: [
        { value: model.valor_sancion, disabled: !model.isupdate },
      ], */
      valor_sancion: [
        model.valor_sancion,
        [Validators.required, Validators.min(1)],
      ],
      isupdate: [model.isupdate],
      descuento_sancion: [0, [Validators.required, Validators.min(0)]],
    });
    this.frmdatasanciones.push(this._frmSanciones);
  }

  async calcularPlanilla() {
    console.log("calculando planilla");
    this.saveItem = false;
    await this.getSubTotalSancionesRegistradas();
    await this.getTotalDescuentoSancionesRegistradas();
    await this.getGranTotalSancionesRegistradas();

    if (this.frmTbPlanilla) {
      this.frmTbPlanilla.controls["_total_minutos_retardo"].patchValue(
        this._subtotal_minutos_retardo -
          this._minutos_sancionados -
          this._descuento_minutos_retardo
      );

      this.frmTbPlanilla.controls["subtotal_minutos_retardo"].patchValue(
        this._subtotal_minutos_retardo - this._minutos_sancionados
      );

      this._total_planilla = 0;
      this.frmTbPlanilla.controls["_suma_total_minutos"].patchValue(0);
      //this.getTotalRetardo();
      this.vr_total_retardo =
        (this._subtotal_minutos_retardo -
          this._minutos_sancionados -
          this._descuento_minutos_retardo) *
        this.vr_minuto_retardo;
      this.frmTbPlanilla.controls["_suma_total_minutos"].patchValue(
        this.vr_total_retardo
      );
    }
    if (this.datos_planilla) {
      this._total_planilla =
        +parseFloat(this.datos_planilla.valor_planilla) +
        +parseFloat(this.datos_planilla.valor_ss_conductor) +
        +parseFloat(this.datos_planilla.valor_relevante) +
        +parseFloat(this.datos_planilla.valor_gps) +
        +parseFloat(this.datos_planilla.valor_bioseguridad) +
        +parseFloat(this.datos_planilla.valor_aporte_cooperativa) +
        +parseFloat(this.datos_planilla.valor_fondo_contingencia) +
        +parseFloat(this.datos_planilla.otras_sanciones) +
        +parseFloat(this.datos_planilla.otros) +
        +this.vr_total_retardo +
        +parseFloat(this.datos_planilla.valor_vias) +
        +this._grantotal_sanciones_registradas +
        +parseFloat(this._ahorro_propietario.toString().replace(",", "")) +
        +parseFloat(this._aporte_cooperativa.toString().replace(",", "")) +
        +parseFloat(this._total_sancion.toString().replace(",", "")) +
        +parseFloat(this._valor_abono_ss.toString().replace(",", "")) +
        +parseFloat(this._valor_abono_ss_relevante.toString().replace(",", ""));
    }
  }

  async getSubTotalSancionesRegistradas() {
    this._subtotal_sanciones_registradas = this.sanciones_registradas
      .map((m) => m.valor_sancion)
      .reduce((acc, curr) => +acc + +curr, 0);
  }
  async getTotalDescuentoSancionesRegistradas() {
    this._totaldescuento_sanciones_registradas = this.sanciones_registradas
      .map((m) => m.descuento_sancion)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async getGranTotalSancionesRegistradas() {
    this._grantotal_sanciones_registradas = this.sanciones_registradas
      .map((m) => m.total_item_sancion)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async getValorTotalMinutosSancionados() {
    this._valor_minutos_sancionados = this.datos_liquidacion
      .map((m) => m.valor_minutos_sancionados)
      .reduce((acc, curr) => +acc + +curr, 0);

    this.frmTbPlanilla.controls["valor_minutos_sancion"].patchValue(
      this._valor_minutos_sancionados
    );
  }

  async getTotalMinutosSancionados() {
    this._minutos_sancionados = this.datos_liquidacion
      .map((m) => m.minutos_sancionados)
      .reduce((acc, curr) => +acc + +curr, 0);

    this.frmTbPlanilla.controls["total_minutos_sancion"].patchValue(
      this._minutos_sancionados
    );
  }

  setAhorroPropietario(value: number) {
    /*  this._ahorro_propietario = value;
    this.calcularPlanilla(); */
  }

  setABonoSS(value: number) {
    /* this._valor_abono_ss = value;
    this.calcularPlanilla(); */
  }

  setAbonoCreditoCooperativa(value: number) {
    /* this._aporte_cooperativa = value;
    this.calcularPlanilla(); */
  }

  setDescuento_Minutos(value: number) {
    let smr = this._totalRetardo; // this.datos_planilla.subtotal_minutos_retardo;
    let dto = parseFloat(value.toString().replace(",", ""));
    if (dto > smr) {
      this.frmLiquidaIsValid = false;
      Swal.fire(
        "<div style='margin-top:20px;'>Atención</div>",
        "Cantidad de minutos descontados es mayor al total de minutos retardo..."
      );
    } else {
      this._total_minutos_retardo = +this._totalRetardo - +dto;
      this._suma_total_minutos = (+this._totalRetardo - +dto) * 1000;
      this.frmLiquidaIsValid = true;
    }
  }
  setDescuento_Sancion(value: number) {
    let vms = this.datos_planilla.valor_minutos_sancion;
    let dto = parseFloat(value.toString().replace(",", ""));
    if (dto > vms) {
      this.frmLiquidaIsValid = false;
      Swal.fire(
        "<div style='margin-top:20px;'>Atención</div>",
        "El valor del descuento es mayor al valor de los minutos sancionados..."
      );
    } else {
      this.frmLiquidaIsValid = true;
    }
  }

  setDescuento_Sanciones(vrsancion: number, dtosancion: number) {
    let vrs = parseFloat(vrsancion.toString().replace(",", ""));
    let dto = parseFloat(dtosancion.toString().replace(",", ""));

    if (dto > vrs) {
      this.frmSancionesIsValid = false;
      Swal.fire(
        "<div style='margin-top:20px;'>Atención</div>",
        "El valor del descuento es mayor al valor de la sanción..."
      );
    } else {
      this.frmSancionesIsValid = true;
    }
  }

  listar_sanciones() {
    this.store.dispatch(cargarSanciones({ pagina: "1", limit: "10" }));
    this.sancionesSubs = this.store
      .select("sanciones")
      .subscribe(({ items, loading }) => {
        this.arraySanciones = items;
        this.loading = loading;
      });
  }

  async listar_sanciones_registradas(planilla_id: any) {
    if (planilla_id) {
      this._servicePlanillaSanciones
        .getByPlanillaId(planilla_id)
        .subscribe(async (resp) => {
          this.sanciones_registradas = resp;
          await this.calcularPlanilla();
        });
    }
  }

  removeAllontrols() {
    for (
      let index = 0;
      index < this.frmSanciones.get("frmArraySanciones")["controls"].length;
      index++
    ) {
      (<FormArray>this.frmSanciones.get("frmArraySanciones")).removeAt(index);
    }
  }
  go_planillas() {
    const fdesde = this.route.snapshot.queryParams["fdesde"];
    const fhasta = this.route.snapshot.queryParams["fhasta"];
    // const term = this.route.snapshot.queryParams["term"];
    this.router.navigate(["/despachos/planillas"], {
      queryParams: {
        fdesde: moment(fdesde).format("YYYY-MM-DD"),
        fhasta: moment(fhasta).format("YYYY-MM-DD"),
        // term: term,
      },
    });
  }

  get_rutas_unq() {
    this.uniqueRutas = Array.from(
      new Set(this.datos_liquidacion.map((item) => item.group_ruta_horasalida))
    );
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  getClass(valuepaso, valuegps) {
    if (valuegps !== "00:00" && valuegps != valuepaso) {
      return "modifyValue";
    } else {
      if (this._retardoString.indexOf("-") !== -1) {
        //Si el valor del retardo es negativo
        return "modifyValueWarning";
      }
    }
  }

  change_despacho(event: any) {
    this.frmTbPlanilla.controls["despacholiquidacion_id"].patchValue(
      event.value
    );
  }

  cambiar_planillaparametro_id(fliquidacion: any, planilla: number) {
    const anio = moment(fliquidacion).format("YYYY");
    const fliquida = this.frmTbPlanilla.controls["fecha_liquidacion"].value; // moment(fliquidacion).format("YYYY-MM-DD");
    console.log("fliquida", fliquida);
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de liquidar con valores del año:</span>',
      html: "<p>" + anio + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._servicePlanillaParametros
          .getByAnio(anio)
          .subscribe((resp: PlanillaParametro) => {
            const ppid = resp[0].planillaparametro_id;
            if (resp) {
              let _model = {
                planillaparametro_id: ppid,
                fecha_liquidacion: fliquida,
              };
              this._servicePlanillas
                .update_parametro(planilla, _model)
                .subscribe((resp) => {
                  console.log("resp update parametros", resp);
                  this.datos_iniciales();
                });
            }
          });
      }
    });
  }

  onFechaLiquidacionChange(event: MatDatepickerInputEvent<Date>) {
    this.frmTbPlanilla.controls["fecha_liquidacion"].patchValue(event.value);
  }

  onChange_GPS(tpso: any, gps: any, i: number) {
    const timepaso = timeStringToMinutes(tpso);
    const timegps = timeStringToMinutes(gps);
    let retardoString = "00:00";

    let retardo = 0;
    if (timegps > 0) {
      retardo = timegps - timepaso;
    }

    /* if (retardo < 10) {
      retardoString = `00:0${retardo.toString()}`;
    } else {
      retardoString = `00:${retardo.toString()}`;
    }
 */
    retardoString = formatMinutesToHHmm(retardo);
    this._retardoString = retardoString;

    (<FormArray>this.frmTbPlanillaLiquidacion.get("frmArrayLugares"))
      .at(i)
      .get("retardo")
      .setValue(retardoString);

    this._totalRetardo = this.getTotalRetardo();

    // Usa el valor calculado de _totalRetardo en las siguientes operaciones Refatorizado chatgpt
    const descuentoMinutos =
      this.frmTbPlanilla.controls["descuento_minutos_retardo"].value || 0;
    this._total_minutos_retardo = this._totalRetardo - descuentoMinutos;

    this._suma_total_minutos = this._total_minutos_retardo * 1000;

    /* this._total_minutos_retardo =
      +this._totalRetardo -
      this.frmTbPlanilla.controls["descuento_minutos_retardo"].value;
    this._suma_total_minutos =
      (+this._totalRetardo -
        +this.frmTbPlanilla.controls["descuento_minutos_retardo"].value) *
      1000; */

    if (this._totalRetardo == 0) {
      this._total_minutos_retardo = 0;
      this._totalRetardo = 0;
      this._suma_total_minutos = 0;
      this.frmTbPlanilla.controls["descuento_minutos_retardo"].patchValue(0);
    }
    this.vr_total_retardo = 0;

    /* ok const item = this.lugarescontrol.at(i) as FormGroup;
    item.get('retardo').setValue(retardo); */
  }

  getTotalRetardo(): number {
    return (
      this.lugarescontrol.controls.reduce((acc, group) => {
        const retardo = group.get("retardo").value;
        return acc + (parseFloat(retardo.toString().replace(":", "")) || 0);
      }, 0) - this._minutos_sancionados
    );
  }

  cerrar_planilla(model: any) {
    let _operacion = "";
    if (model.cerrada === 1) {
      _operacion = "Abrir";
      const expectedRoles = ["abrirplanilla"];
      if (!this.list_roles.some((r) => expectedRoles.includes(r))) {
        Swal.fire(
          "Atención",
          "No tiene permisos para abrir la planilla",
          "warning"
        );
        return;
      }
    } else {
      _operacion = "Cerrar";
      const expectedRoles = ["cerrarplanilla"];
      if (!this.list_roles.some((r) => expectedRoles.includes(r))) {
        Swal.fire(
          "Atención",
          "No tiene permisos para cerrar la planilla",
          "warning"
        );
        return;
      }
    }
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de ' +
        _operacion +
        " la Planilla?</span>",
      html: "<p><b>Consecutivo:</b> " + model.planilla_id + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        /* //this.cerrarPlanillaSubscription =
        let rs = this._servicePlanillas.update_cerrarplanilla(
          model.planilla_id.toString(),
          model
        );
        console.log("rs", rs);
        this, this.datos_iniciales(); */
        this.cerrarPlanillaSubscription = this._servicePlanillas
          .update_cerrarplanilla(model.planilla_id.toString(), model)
          .subscribe(
            (resp: any) => {
              if (resp.status === "ok") {
                this.datos_iniciales();
                /* this.store.dispatch(
                cargarPlanillasMasterByPlanilla_id({
                  planilla_id: model.planilla_id,
                })
              ); */
              } else {
                Swal.fire("Atención", resp.msg, "warning");
              }
            },
            (error) => {
              Swal.fire("Error", "No se pudo cerrar la planilla", error);
            }
          );
      }
    });
  }

  //Guardar datos de planilla y liquidación

  //Refactorizado DeepSeek
  async onSubmitForms(): Promise<void> {
    this._serviceToastr.showWarning("Preparando datos. Espere un momento...");
    if (this.frmTbPlanilla.valid && this.frmTbPlanillaLiquidacion.valid) {
      try {
        await this.submitTbPlanilla(this.frmTbPlanilla);
        await this.submitTbPlanillaLiquidacion(this.frmTbPlanillaLiquidacion);
        this.datos_iniciales();
      } catch (error) {
        console.error("Error al procesar los formularios:", error);
      }
    } else {
      console.error("Uno o ambos formularios no son válidos.");
    }
  }

  submitTbPlanilla(frmLiq: any): Promise<void> {
    console.log("frmLiq.value", frmLiq.value);
    return new Promise((resolve, reject) => {
      this.saveItem = false;
      this.subsAll$.add(
        this._servicePlanillas
          .updateliquidacion(frmLiq.value.planilla_id, frmLiq.value)
          .subscribe(
            (resp: any) => {
              if (resp.status === "ok") {
                this._serviceToastr.showInfo(
                  "Planilla actualizada correctamente..."
                );
              } else {
                this._serviceToastr.showError(resp.msg);
              }
              resolve();
            },
            (error) => {
              reject(error);
            }
          )
      );
    });
  }

  submitTbPlanillaLiquidacion(frm: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const promises = [];
      for (
        let index = 0;
        index <
        this.frmTbPlanillaLiquidacion.get("frmArrayLugares")["controls"].length;
        index++
      ) {
        const promise = new Promise<void>((innerResolve, innerReject) => {
          this.subsAll$.add(
            this._serviceLiquidacion
              .update(
                (<FormArray>(
                  this.frmTbPlanillaLiquidacion.get("frmArrayLugares")
                )).at(index).value.planillaliquidacion_id,
                (<FormArray>(
                  this.frmTbPlanillaLiquidacion.get("frmArrayLugares")
                )).at(index).value
              )
              .subscribe(
                (resp: any) => {
                  if (resp.status === "ok") {
                    this._serviceToastr.showSuccess(
                      "Guardando datos del GPS..."
                    );
                  } else {
                    this._serviceToastr.showError(resp.msg);
                  }
                  innerResolve();
                },
                (error) => {
                  innerReject(error);
                }
              )
          );
        });
        promises.push(promise);
      }
      Promise.all(promises)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  }

  ngOnDestroy(): void {
    this.sancionesSubs.unsubscribe();
    this.planillasSubscription.unsubscribe();
    this.cerrarPlanillaSubscription.unsubscribe();
    this.sub.unsubscribe();
    this.subsAll$.unsubscribe();
  }

  onTimeInput(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    console.log("Hora ingresada manualmente:", input);
  }

  disableTimePicker(event: FocusEvent): void {
    // Prevenir la apertura del selector de tiempo nativo
    (event.target as HTMLInputElement).blur();
  }
}

function timeStringToMinutes(time: string): number {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}

function formatMinutesToHHmm(minutes: number): string {
  const isNegative = minutes < 0; // Verifica si el valor es negativo
  const absoluteMinutes = Math.abs(minutes); // Obtiene el valor absoluto

  const hours = Math.floor(absoluteMinutes / 60); // Calcula las horas
  const mins = absoluteMinutes % 60; // Calcula los minutos restantes

  // Formatea las horas y minutos con ceros iniciales
  const formattedTime = `${hours.toString().padStart(2, "0")}:${mins
    .toString()
    .padStart(2, "0")}`;

  // Agrega el signo negativo si el valor original era negativo
  return isNegative ? `-${formattedTime}` : formattedTime;
}
