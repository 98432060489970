import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PosPrintComponent } from "./pos-print/pos-print.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PosPrinterConfigComponent } from "./configuration/pos-printer-config.component";
import { BlockUIModule } from "ng-block-ui";
import { BlockTemplateComponent } from "../templates/block-template.component";
import { MatFormFieldModule, MatSelectModule } from "@angular/material";

@NgModule({
  declarations: [PosPrintComponent, PosPrinterConfigComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent,
    }),
  ],
  exports: [PosPrintComponent],
})
export class PosPrintModule {}
