import { Component, Input, OnInit } from "@angular/core";
import { LogirastreosService } from "../../services/logirastreos.service";

@Component({
  selector: "app-rpt-planillas-logirastreos",
  templateUrl: "./rpt-planillas-logirastreos.component.html",
  styleUrls: ["./rpt-planillas-logirastreos.component.css"],
})
export class RptPlanillasLogirastreosComponent implements OnInit {
  @Input() data_rpt = [];
  @Input() tituloReporte = "";
  FechaDesde = "";
  FechaHasta = "";
  @Input() chkBus = false;
  @Input() _bus = "";
  @Input() _placa = "";

  constructor(private _serviceLogirastreos: LogirastreosService) {
    this._serviceLogirastreos.EventDatosLogirasteos$.subscribe(
      async (resp: any) => {
        this.data_rpt = resp;
      }
    );

    this._serviceLogirastreos.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceLogirastreos.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });
  }

  ngOnInit() {}

  exportAsXLSX() {}
}
