import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import Swal from "sweetalert2";
import { URL_API } from "../../../../config/config";

@Injectable({
  providedIn: "root",
})
export class ReportesDespachosService {
  httpOptions = {};

  EventDatosAhorros$ = new EventEmitter<any>();
  EventDatosAbonosSS$ = new EventEmitter<any>();
  EventDatosTotalesRecaudos$ = new EventEmitter<any>();
  EventDatosDescuentos$ = new EventEmitter<any>();
  EventDatosArqueo$ = new EventEmitter<any>();
  EventDatosArqueoRetiros$ = new EventEmitter<any>();
  EventDatosInformePlanillas$ = new EventEmitter<any>();
  EventDatosInformeCooperativa$ = new EventEmitter<any>();
  EventDatosInformeMovimientosTotales$ = new EventEmitter<any>();
  EventDatosInformeMovimientosTotalesCooperativa$ = new EventEmitter<any>();
  EventDatosInformeDiasTrabajados$ = new EventEmitter<any>();

  mes$ = new EventEmitter<String>();
  bus$ = new EventEmitter<String>();
  saldoviene$ = new EventEmitter<number>();
  saldoquepasa$ = new EventEmitter<number>();
  diascalendario$ = new EventEmitter<number>();
  totalabonosss$ = new EventEmitter<number>();
  FechaDesde$ = new EventEmitter<any>();
  FechaHasta$ = new EventEmitter<any>();
  blob: any = undefined;
  loading$ = new EventEmitter<boolean>();
  _mes = "";

  constructor(private http: HttpClient) {
    this.mes$.subscribe((resp) => {
      this._mes = resp;
    });
  }

  getAhorroPropietario(
    page: string,
    limit: string,
    desde: string,
    hasta: string,
    condicion: string
  ) {
    this.loadAuth();
    if (condicion.length === 0) {
      condicion = "-1";
    }
    let url =
      URL_API +
      "/reportes_despachos/get_ahorro_propietario/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta +
      "/" +
      condicion;
    return this.http.get(url, this.httpOptions);
  }

  get_conceptoByBus(
    desde: string,
    hasta: string,
    bus: string,
    cerrada: number
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_conceptobybus/${desde}/${hasta}/${bus}/${cerrada}`;
    return this.http.get(url, this.httpOptions);
  }

  get_concepto_general(desde: string, hasta: string, cerrada: number) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_conceptogeneral/${desde}/${hasta}/${cerrada}`;
    return this.http.get(url, this.httpOptions);
  }

  get_informe_para_cobro(desde: string, hasta: string) {
    this.loadAuth();
    let url =
      URL_API + `/reportes_despachos/get_informe_para_cobro/${desde}/${hasta}`;
    return this.http.get(url, this.httpOptions);
  }

  get_informe_para_cobrobydespachoid(
    desde: string,
    hasta: string,
    despacho_id: number,
    cerrada: number
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_informe_para_cobrobydespachoid/${desde}/${hasta}/${despacho_id}/${cerrada}`;
    console.log(url);
    return this.http.get(url, this.httpOptions);
  }

  get_informe_para_cobrobydespachoidAndBus(
    desde: string,
    hasta: string,
    despacho_id: number,
    bus: string,
    cerrada: number
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_informe_para_cobrobydespachoidandbus/${desde}/${hasta}/${despacho_id}/${cerrada}/${bus}`;
    console.log(url);
    return this.http.get(url, this.httpOptions);
  }

  get_informe_para_cobrobyBus(
    desde: string,
    hasta: string,
    bus: string,
    cerrada: number
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_informe_para_cobrobybus/${desde}/${hasta}/${cerrada}/${bus}`;
    console.log(url);
    return this.http.get(url, this.httpOptions);
  }

  get_informe_para_cobrobyPlanilla(planilla_id: number) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_informe_para_cobrobyplanilla/${planilla_id}`;
    console.log(url);
    return this.http.get(url, this.httpOptions);
  }

  get_informe_para_cobro_all(desde: string, hasta: string, cerrada: number) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_informe_para_cobro_all/${desde}/${hasta}/${cerrada}`;
    return this.http.get(url, this.httpOptions);
  }

  get_informe_para_cobro_allbybus(
    desde: string,
    hasta: string,
    cerrada: number,
    bus: string
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_informe_para_cobro_all/${desde}/${hasta}/${cerrada}/${bus}`;
    return this.http.get(url, this.httpOptions);
  }

  get_informe_cooperativabydespachoid(
    desde: string,
    hasta: string,
    despacho_id: number,
    cerrada: number
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_informe_cooperativabydespachoid/${desde}/${hasta}/${cerrada}/${despacho_id}`;
    return this.http.get(url, this.httpOptions);
  }

  get_informe_cooperativa_all(desde: string, hasta: string, cerrada: number) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_informe_cooperativa_all/${desde}/${hasta}/${cerrada}`;
    return this.http.get(url, this.httpOptions);
  }

  get_informe_cooperativabybus(
    desde: string,
    hasta: string,
    cerrada: number,
    bus: string
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_informe_cooperativabybus/${desde}/${hasta}/${cerrada}/${bus}`;
    return this.http.get(url, this.httpOptions);
  }

  get_informe_cooperativabydespachoidAndbus(
    desde: string,
    hasta: string,
    despacho_id: number,
    bus: string,
    cerrada: number
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_informe_cooperativabydespachoidandbus/${desde}/${hasta}/${despacho_id}/${cerrada}/${bus}`;
    return this.http.get(url, this.httpOptions);
  }

  //Movimientos Totales
  get_informe_movimientos_totales_all(
    desde: string,
    hasta: string,
    cerrada: number
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_movimientos_totales/${desde}/${hasta}/${cerrada}`;
    return this.http.get(url, this.httpOptions);
  }

  //Movimientos Totales Cooperativa
  get_informe_movimientos_totales_cooperativa_all(
    desde: string,
    hasta: string,
    cerrada: number
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_movimientos_totales_cooperativa/${desde}/${hasta}/${cerrada}`;
    return this.http.get(url, this.httpOptions);
  }

  //Dias Trabajados
  get_dias_trabajados(vehiculo_id: number, desde: string, hasta: string) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_dias_trabajados/${vehiculo_id}/${desde}/${hasta}`;
    return this.http.get(url, this.httpOptions);
  }

  get_abonos_ssByBus(desde: string, hasta: string, bus: string) {
    this.loadAuth();
    let url =
      URL_API +
      `/planillas_abonos_ss/get_abonos_ssbybus/${desde}/${hasta}/${bus}`;
    return this.http.get(url, this.httpOptions);
  }
  get_abonos_ssByBusPaginado(
    page: string,
    limit: string,
    desde: string,
    hasta: string,
    bus: string
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/planillas_abonos_ss/get_abonos_ssbybus/${page}/${limit}/${desde}/${hasta}/${bus}`;
    return this.http.get(url, this.httpOptions);
  }
  get_abonos_ss_general(desde: string, hasta: string) {
    this.loadAuth();
    let url =
      URL_API + `/planillas_abonos_ss/get_abonos_ss_general/${desde}/${hasta}`;
    return this.http.get(url, this.httpOptions);
  }

  get_icontable(desde: string, hasta: string, interfaz: number) {
    this.loadAuth();
    let url =
      URL_API +
      `/reportes_despachos/get_icontable/${desde}/${hasta}/${interfaz}`;
    return this.http.get(url, this.httpOptions);
  }

  downloadFile(
    data: any,
    fileName: string,
    nmes: string,
    nbus: string,
    periodo: string
  ) {
    this.loadAuth();
    if (nbus.length === 0) {
      nbus = "-1";
    }
    this.mes$.emit(nmes);
    this.bus$.emit(nbus);
    let url =
      URL_API +
      "/reportes_despachos/exportarproducidos/" +
      nmes +
      "/" +
      nbus +
      "/" +
      periodo;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_Producidos(data: any, fileName: string, periodo: string) {
    this.loadAuth();
    let url = URL_API + "/reportes_despachos/exportarproducidos/" + periodo;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_Retiros(data: any, fileName: string, periodo: string) {
    this.loadAuth();
    let url = URL_API + "/reportes_despachos/exportarretiros/" + periodo;
    console.log(url);
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_Movimientos(
    data: any,
    fileName: string,
    periodo: string,
    cuentabanco: string
  ) {
    this.loadAuth();
    console.log(data);
    let url =
      URL_API +
      "/reportes_despachos/exportarmovimientos/" +
      periodo +
      "/" +
      cuentabanco;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );

    // return this.http.post(url, data);
  }

  downloadFile_TotalesRecaudos(data: any, fileName: string, periodo: string) {
    this.loadAuth();
    console.log(data);
    let url =
      URL_API + "/reportes_despachos/exportartotalesrecaudos/" + periodo;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_InformePlanillas(data: any, fileName: string, periodo: string) {
    this.loadAuth();
    let url = URL_API + "/reportes_despachos/exportinformeplanillas/" + periodo;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_InformeMovimientosTotal(
    data: any,
    fileName: string,
    periodo: string
  ) {
    this.loadAuth();
    let url =
      URL_API + "/reportes_despachos/exportinformemovimientostotal/" + periodo;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_InformeMovimientosTotalCooperativa(
    data: any,
    fileName: string,
    periodo: string
  ) {
    this.loadAuth();
    let url =
      URL_API +
      "/reportes_despachos/exportinformemovimientostotalcooperativa/" +
      periodo;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_InformeDiasTrabajados(
    data: any,
    fileName: string,
    periodo: string
  ) {
    this.loadAuth();
    let url =
      URL_API + "/reportes_despachos/exportinformediastrabajados/" + periodo;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_InformePropietario(
    data: any,
    fileName: string,
    periodo: string
  ) {
    this.loadAuth();
    let url =
      URL_API + "/reportes_despachos/exportinformepropietario/" + periodo;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_IContable(data: any, fileName: string) {
    this.loadAuth();
    let url = URL_API + "/reportes_despachos/exporticontable";
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_InformeCooperativa(
    data: any,
    fileName: string,
    periodo: string
  ) {
    this.loadAuth();
    let url =
      URL_API + "/reportes_despachos/exportinformecooperativa/" + periodo;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
