import { Component } from "@angular/core";
import { PosPrintService } from "../pos-print.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-pos-print",
  templateUrl: "./pos-print.component.html",
  styleUrls: ["./pos-print.component.css"],
})
export class PosPrintComponent {
  printData: string = "";

  constructor(private http: HttpClient) {}

  print() {
    if (this.printData) {
      // Aquí puedes enviar los datos a una API que maneje la impresora POS
      this.http
        .post("http://localhost:5000/api/pos_printer/imprimir", {
          data: this.printData,
        })
        .subscribe(
          (response) => {
            console.log("Impresión exitosa:", response);
          },
          (error) => {
            console.error("Error al imprimir:", error);
          }
        );
    } else {
      console.warn("No hay datos para imprimir");
    }
  }
}
