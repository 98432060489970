import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { PlanillaParametrosComponent } from "./pages/planilla-parametros/planilla-parametros.component";
import { PlanillasComponent } from "./pages/planillas/planillas.component";
import { DespachosRoutingModule } from "./despachos-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PanelxComponent } from "./pages/panelx/panelx.component";
import { PaginadorxComponent } from "./pages/paginadorx/paginadorx.component";
import {
  MatCardModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
  MatSelectModule,
} from "@angular/material";
import { NewEditComponent } from "./pages/planilla-parametros/new-edit/new-edit.component";
import { AniosNewEditComponent } from "./pages/anios/new-edit/new-edit.component";
import { PlanillasConsecutivosComponent } from "./pages/planillas-consecutivos/planillas-consecutivos.component";
import { PcNewEditComponent } from "./pages/planillas-consecutivos/new-edit/new-edit.component";
import { BlockTemplateComponent } from "./templates/block-template.component";
import { BlockUIModule } from "ng-block-ui";
import { RutasDespachosComponent } from "./pages/rutas-despachos/rutas-despachos.component";
import { RutasDespachosNewEditComponent } from "./pages/rutas-despachos/new-edit/new-edit.component";
import { PlanillasLiquidacionComponent } from "./pages/planillas-liquidacion/planillas-liquidacion.component";
import { PlNewEditComponent } from "./pages/planillas-liquidacion/new-edit/new-edit.component";
import { PlanillaNewEditComponent } from "./pages/planillas/new-edit/new-edit.component";
import { RecorridoComponent } from "./pages/planillas/recorrido/recorrido.component";
import {
  NgbModule,
  NgbTimepickerModule,
  NgbTooltipConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { LugaresControlComponent } from "./pages/lugares-control/lugares-control.component";
import { LugaresControlNewEditComponent } from "./pages/lugares-control/new-edit/new-edit.component";
import { RutasDespachosLugaresComponent } from "./pages/rutasdespachos-lugares/rutasdespachos-lugares.component";
import { RutasDespachosLugaresNewEditComponent } from "./pages/rutasdespachos-lugares/new-edit/new-edit.component";
import { PlanillaRutaDespachoNewEditComponent } from "./pages/planillas-rutasdespachos/new-edit/new-edit.component";
import { RutasDespachosxPlanillaComponent } from "./pages/planillas-rutasdespachos/rutasdespachosxplanilla.component";
import { SancionesComponent } from "./pages/sanciones/sanciones.component";
import { SancionesNewEditComponent } from "./pages/sanciones/new-edit/new-edit.component";
import { GeolocalizacionComponent } from "./pages/geolocalizacion/geolocalizacion/geolocalizacion.component";
import { PlanillasSancionesComponent } from "./pages/planillas-sanciones/planillas_sanciones.component";
import { PlanillaSancionNewEditComponent } from "./pages/planillas-sanciones/new-edit/new-edit.component";
import { ToastrModule } from "ngx-toastr";
import { ListReportesDespachosComponent } from "./pages/reportes/list-reportes/list-reportes-despachos.component";
import { ReportesDespachosComponent } from "./pages/reportes/reportes-despachos.component";
import { RptConceptosComponent } from "./pages/reportes/rpt/rpt-conceptos.component";
import { RptAbonosSSComponent } from "./pages/reportes/rpt/rpt-abonos-ss.component";
import { PlanillasAbonosSSComponent } from "./pages/planillas-abonos-ss/planillas-abonos-ss.component";
import { PlanillasAbonosSSNewComponent } from "./pages/planillas-abonos-ss/planillas-abonos-ss-new.component";
import { PlanillasAbonosSSListComponent } from "./pages/planillas-abonos-ss/planillas-abonos-ss-list.component";
import { PlanillasAbonosSSListResponseComponent } from "./pages/planillas-abonos-ss/planillas-abonos-ss-list-response.component";
import { ConsultaPlanillasAbonosSSComponent } from "./pages/planillas-abonos-ss/consulta-planillas-abonos-ss.component";
import { PageNotFoundComponent } from "../components/page-not-found/page-not-found.component";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { PrintPageComponent } from "./pages/print-page/print-page.component";
import { CardsRutasComponent } from "./pages/utils/cards-rutas/cards-rutas.component";
import { AgmCoreModule } from "@agm/core";
import { GoogleMapsModule } from "@angular/google-maps";
import { PrintPlanillaComponent } from "./pages/planillas/print-planilla/print-planilla.component";
import { PlanillaRecorridoComponent } from "./pages/planillas/planilla-recorrido/planilla-recorrido.component";
import { QRCodeModule } from "angularx-qrcode";
import { PlanillaConceptosPagarComponent } from "./pages/planillas/planilla-conceptos-pagar/planilla-conceptos-pagar.component";
import { RptInformePlanillasComponent } from "./pages/reportes/rpt/rpt-informe-planillas.component";
import { NgxPrintModule } from "ngx-print";
import { RptInformeCooperativaComponent } from "./pages/reportes/rpt/rpt-informe-cooperativa.component";
import { MonitorPlanillasComponent } from "./pages/monitor-planillas/monitor-planillas.component";
import { NgNumericKeyboardModule } from "ng-numeric-keyboard";
import { ResumenLiquidacionComponent } from "./pages/planillas/resumen-liquidacion/resumen-liquidacion.component";
import {
  IKeyboardLayouts,
  MAT_KEYBOARD_LAYOUTS,
  MatKeyboardModule,
  keyboardLayouts,
} from "angular-onscreen-material-keyboard";

const maskConfig: Partial<IConfig> = {
  validation: false,
  // showMaskTyped: true,
  // clearIfNotMatch: true,
};

import localeEs from "@angular/common/locales/es";
import { RptInformeMovimientosTotalesComponent } from "./pages/reportes/rpt/rpt-informe-movimientos-totales.component";
import { RptInformeMovimientosTotalesCooperativaComponent } from "./pages/reportes/rpt/rpt-informe-movimientos-totales-cooperativa.component";
import { LogirastreosComponent } from "./pages/logirastreos/pages/logirastreos.component";
import { RptPlanillasLogirastreosComponent } from "./pages/logirastreos/pages/rpt/rpt-planillas-logirastreos.component";
import { RptInformeDiasTrabajadosComponent } from "./pages/reportes/rpt/rpt-informe-dias-trabajados.component";
import { DashboardRetardosComponent } from "./dashboard/dashboard-retardos.component";
import { DashboardConsultasComponent } from "./dashboard/dashboard-consultas/dashboard-consultas.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
registerLocaleData(localeEs, "es");

const customLayouts: IKeyboardLayouts = {
  ...keyboardLayouts,
  "Tölles Läyout": {
    name: "Awesome layout",
    keys: [
      [
        ["1", "!"],
        ["2", "@"],
        ["3", "#"],
        ["4", "$"],
        ["5", "%"],
        ["6", "&"],
        ["7", "7"],
        ["8", "("],
        ["9", ")"],
        ["0", "="],
      ],
    ],
    lang: ["de-CH"],
  },
};

@NgModule({
  declarations: [
    PlanillaParametrosComponent,
    PlanillasConsecutivosComponent,
    PlanillasLiquidacionComponent,
    PlanillasComponent,
    PlanillaNewEditComponent,
    PlanillaRutaDespachoNewEditComponent,
    RutasDespachosxPlanillaComponent,
    PanelxComponent,
    PaginadorxComponent,
    NewEditComponent,
    AniosNewEditComponent,
    PcNewEditComponent,
    PlNewEditComponent,
    BlockTemplateComponent,
    RutasDespachosComponent,
    RutasDespachosNewEditComponent,
    RecorridoComponent,
    LugaresControlComponent,
    LugaresControlNewEditComponent,
    RutasDespachosLugaresComponent,
    RutasDespachosLugaresNewEditComponent,
    SancionesComponent,
    SancionesNewEditComponent,
    PlanillasSancionesComponent,
    PlanillaSancionNewEditComponent,
    ListReportesDespachosComponent,
    ReportesDespachosComponent,
    RptConceptosComponent,
    RptAbonosSSComponent,
    PlanillasAbonosSSComponent,
    PlanillasAbonosSSNewComponent,
    PlanillasAbonosSSListComponent,
    PlanillasAbonosSSListResponseComponent,
    ConsultaPlanillasAbonosSSComponent,
    GeolocalizacionComponent,
    PageNotFoundComponent,
    PrintPageComponent,
    CardsRutasComponent,
    PrintPlanillaComponent,
    PlanillaRecorridoComponent,
    PlanillaConceptosPagarComponent,
    RptInformePlanillasComponent,
    RptInformeMovimientosTotalesComponent,
    RptInformeMovimientosTotalesCooperativaComponent,
    RptInformeDiasTrabajadosComponent,
    RptInformeCooperativaComponent,
    RptPlanillasLogirastreosComponent,
    MonitorPlanillasComponent,
    ResumenLiquidacionComponent,
    LogirastreosComponent,
    DashboardRetardosComponent,
    DashboardConsultasComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    DespachosRoutingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    NgbTimepickerModule,
    QRCodeModule,
    NgxPrintModule,
    NgxChartsModule,
    NgxMaskModule.forRoot(maskConfig),
    BlockUIModule.forRoot({
      template: BlockTemplateComponent,
    }),
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey:
        "AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg&callback=initMap&v=weekly",
    }),
    GoogleMapsModule,
    NgNumericKeyboardModule,
    MatKeyboardModule,
  ],
  entryComponents: [
    BlockTemplateComponent,
    PlanillaNewEditComponent,
    PlanillaRutaDespachoNewEditComponent,
    LugaresControlNewEditComponent,
    RutasDespachosLugaresNewEditComponent,
    SancionesNewEditComponent,
    PlanillasAbonosSSNewComponent,
    PlanillaRecorridoComponent,
    PlanillaConceptosPagarComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    NgbTooltipConfig,
    ToastrModule,
    {
      provide: LOCALE_ID,
      useValue: "es",
    },
    {
      provide: MAT_KEYBOARD_LAYOUTS,
      useValue: customLayouts,
    },
  ],
})
export class DespachosModule {}
