import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-dashboard-consultas",
  templateUrl: "./dashboard-consultas.component.html",
  styleUrls: ["./dashboard-consultas.component.css"],
})
export class DashboardConsultasComponent implements OnInit {
  chartData = [
    { name: "V-229", value: 587 },
    { name: "V-195", value: 581 },
    { name: "V-216", value: 537 },
    { name: "V-201", value: 532 },
    { name: "V-230", value: 517 },
    { name: "V-113", value: 505 },
    { name: "V-157", value: 502 },
    { name: "V-143", value: 491 },
    { name: "V-193", value: 486 },
    { name: "V-228", value: 485 },
    // { name: "V-117", value: 484 },
    // { name: "V-126", value: 480 },
    // { name: "V-191", value: 478 },
    // { name: "V-207", value: 474 },
    // { name: "V-210", value: 458 },
    // { name: "V-147", value: 452 },
    // { name: "V-214", value: 451 },
    // { name: "V-213", value: 449 },
    // { name: "V-167", value: 443 },
    // { name: "V-231", value: 433 },
    // { name: "V-144", value: 432 },
    // { name: "V-166", value: 421 },
    // { name: "V-200", value: 412 },
    // { name: "V-227", value: 410 },
    // { name: "V-154", value: 407 },
    // { name: "V-153", value: 405 },
    // { name: "V-115", value: 404 },
    // { name: "V-148", value: 404 },
    // { name: "V-206", value: 404 },
    // { name: "V-133", value: 403 },
    // { name: "V-225", value: 402 },
    // { name: "V-132", value: 401 },
    // { name: "V-150", value: 398 },
    // { name: "V-138", value: 391 },
    // { name: "V-196", value: 391 },
    // { name: "V-130", value: 390 },
    // { name: "V-111", value: 385 },
    // { name: "V-121", value: 384 },
    // { name: "V-155", value: 384 },
    // { name: "V-162", value: 382 },
    // { name: "V-190", value: 377 },
    // { name: "V-181", value: 376 },
    // { name: "V-178", value: 373 },
    // { name: "V-222", value: 372 },
    // { name: "V-224", value: 371 },
    // { name: "V-156", value: 369 },
    // { name: "V-135", value: 368 },
    // { name: "V-140", value: 368 },
    // { name: "V-198", value: 365 },
    // { name: "V-122", value: 364 },
    // { name: "V-182", value: 362 },
    // { name: "V-226", value: 361 },
    // { name: "V-125", value: 360 },
    // { name: "V-123", value: 359 },
    // { name: "V-127", value: 358 },
    // { name: "V-179", value: 356 },
    // { name: "V-128", value: 353 },
    // { name: "V-141", value: 352 },
    // { name: "V-116", value: 348 },
    // { name: "V-176", value: 339 },
    // { name: "V-149", value: 337 },
    // { name: "V-139", value: 336 },
    // { name: "V-165", value: 336 },
    // { name: "V-160", value: 335 },
    // { name: "V-208", value: 330 },
    // { name: "V-187", value: 329 },
    // { name: "V-124", value: 327 },
    // { name: "V-174", value: 326 },
    // { name: "V-137", value: 325 },
    // { name: "V-108", value: 322 },
    // { name: "V-177", value: 318 },
    // { name: "V-189", value: 316 },
    // { name: "V-159", value: 314 },
    // { name: "V-142", value: 313 },
    // { name: "V-106", value: 311 },
    // { name: "V-170", value: 311 },
    // { name: "V-184", value: 311 },
    // { name: "V-171", value: 306 },
    // { name: "V-173", value: 306 },
    // { name: "V-232", value: 306 },
    // { name: "V-145", value: 305 },
    // { name: "V-220", value: 305 },
    // { name: "V-118", value: 303 },
    // { name: "V-134", value: 303 },
    // { name: "V-194", value: 303 },
    // { name: "V-183", value: 299 },
    // { name: "V-163", value: 295 },
    // { name: "V-110", value: 292 },
    // { name: "V-192", value: 285 },
    // { name: "V-107", value: 284 },
    // { name: "V-109", value: 282 },
    // { name: "V-161", value: 281 },
    // { name: "V-168", value: 281 },
    // { name: "V-164", value: 278 },
    // { name: "V-146", value: 276 },
    // { name: "V-131", value: 275 },
    // { name: "V-169", value: 270 },
    // { name: "V-175", value: 270 },
    // { name: "V-217", value: 269 },
    // { name: "V-204", value: 262 },
    // { name: "V-223", value: 257 },
    // { name: "V-180", value: 254 },
    // { name: "V-185", value: 249 },
    // { name: "V-152", value: 247 },
    // { name: "V-151", value: 242 },
    // { name: "V-219", value: 239 },
    // { name: "V-197", value: 238 },
    // { name: "V-119", value: 232 },
    // { name: "V-136", value: 232 },
    // { name: "V-202", value: 227 },
    // { name: "V-221", value: 226 },
    // { name: "V-199", value: 216 },
    // { name: "V-205", value: 215 },
    // { name: "V-188", value: 210 },
    // { name: "V-158", value: 207 },
    // { name: "V-114", value: 206 },
    // { name: "V-215", value: 199 },
    // { name: "V-186", value: 196 },
    // { name: "V-172", value: 189 },
    // { name: "V-218", value: 181 },
    // { name: "V-203", value: 177 },
    // { name: "V-129", value: 175 },
    // { name: "V-112", value: 165 },
    // { name: "V-209", value: 150 },
    // { name: "V-120", value: 146 },
    // { name: "V-212", value: 106 },
    // { name: "V-211", value: 25 },
  ];

  constructor() {}

  ngOnInit() {}
}
