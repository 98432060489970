import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { URL_API, URL_API_HTTPS } from "../config/config";
import { Vehiculo } from "../models/vehiculos.model";
import { catchError, map } from "rxjs/operators";
import Swal from "sweetalert2";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class VehiculosService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(private http: HttpClient) {}

  get_listar(page: string, limit: string) {
    const url = URL_API + "/vehiculos/get_listar/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_listar_https(page: string, limit: string) {
    const url = URL_API_HTTPS + "/vehiculos/get_listar/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_listar_veh_propietarios(page: string, limit: string) {
    const url =
      URL_API + "/vehiculos/get_listar_veh_propietarios/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_listar_veh_propietarios_unq(page: string, limit: string) {
    const url =
      URL_API +
      "/vehiculos/get_listar_veh_propietarios_unq/" +
      page +
      "/" +
      limit;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    const url =
      URL_API + "/vehiculos/get_buscar/" + pagina + "/" + limit + "/" + buscar;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar_propietarios_vehiculos(
    pagina: string,
    limit: string,
    buscar: string
  ) {
    const url =
      URL_API +
      "/vehiculos/get_buscar_veh_propietarios/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar_propietarios_vehiculos_unq(
    pagina: string,
    limit: string,
    buscar: string
  ) {
    const url =
      URL_API +
      "/vehiculos/get_buscar_veh_propietarios_unq/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar_veh_recaudos_unq(buscar: string) {
    const url = `${URL_API}/vehiculos/get_buscar_veh_recaudos_unq/${buscar}`;
    return this.http.get(url, this.httpOptions);
  }

  create(model: any) {
    const url = URL_API + "/vehiculos/nuevo";
    return this.http.post(url, model, this.httpOptions);
  }

  getbyid(id: string) {
    const url = URL_API + "/vehiculos/get_byid/" + id;
    return this.http.get(url, this.httpOptions);
  }

  getbynumero(numero: string) {
    const url = URL_API + "/vehiculos/get_bynumero/" + numero;
    return this.http.get(url, this.httpOptions);
  }

  numeroExiste(value: string) {
    let url = URL_API + `/vehiculos/get_numeroexiste/${value}`;
    return this.http.get(url).pipe(
      map((resp: any) => resp),
      catchError((err) => {
        Swal.fire("Error", err, "error");
        return of(false);
      })
    );
  }

  placaExiste(value: string) {
    let url = URL_API + `/vehiculos/get_placaexiste/${value}`;
    return this.http.get(url).pipe(
      map((resp: any) => resp),
      catchError((err) => {
        Swal.fire("Error", err, "error");
        return of(false);
      })
    );
  }

  update(id: string, model: Vehiculo) {
    const url = URL_API + "/vehiculos/update/" + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete(id: string) {
    const url = URL_API + "/vehiculos/delete/" + id;
    return this.http.delete(url, this.httpOptions);
  }
}
