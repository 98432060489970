import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDialog,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { Anio } from "src/app/models/anios.model";
import { PlanillaRutaDespacho } from "src/app/models/planillas_rutasdespachos.model";
import { PlanillaRutasDespachosService } from "../services/planillas_rutasdespachos.service";
import { DlgRutasDespachosComponent } from "src/app/busquedas/dlg-rutas-despachos.component";
import * as moment from "moment";
import Swal from "sweetalert2";
import { FechaHoraSalidaValidator } from "src/app/validators/form-validators";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-new-edit",
  templateUrl: "./new-edit.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PlanillaRutaDespachoNewEditComponent implements OnInit {
  @BlockUI("data-section_edit") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  @Output() submitClicked = new EventEmitter<any>();
  frm: FormGroup;
  loading: boolean = false;
  _userSession: any = "0";
  _numero = "";
  _rutadespacho = "";
  _condid = 0;
  anios: Anio[] = [];
  isEdit: boolean = false;
  _model: PlanillaRutaDespacho;
  data_search_rutasdespachos = [];
  data_ultima_ruta: PlanillaRutaDespacho;
  date: Date[] | undefined;
  isHoraSalidaOk: boolean = false;

  _ultimallegada: any;
  _ultimaruta: any;
  _tiempo_paso: any;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private _serviceModel: PlanillaRutasDespachosService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));

    // setTimeout(() => {
    this.frm = this.fb.group({
      planillarutadespacho_id: [0],
      planilla_id: [this._model.planilla_id],
      rutadespacho_id: [0, Validators.required],
      fechahora_salida: [""],
      _fechahora_salida: [
        "",
        {
          validators: Validators.required,
          /*  asyncValidators: [this.validarFechaHoraRemota.bind(this)],
          updateOn: "blur", */
        },
      ],
      _numero: [this._model.numero],
      _rutadespacho: [""],
      _consecutivo: [this._model.planilla_id],
      _fecha_planilla: [
        moment(this._model.fecha_planilla).format("DD-MM-YYYY"),
      ],
    });
    // }, 500);

    if (this.isEdit) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      const id = this._model.planillarutadespacho_id;
      this.setForm(id);
    }

    // this.get_ultima_llegada();
    this.get_ultima_ruta_asignada();
  }

  validarFechaHoraRemota(value: any) {
    let fsalida = this._model.fecha_planilla + " " + value;
    this._serviceModel
      .get_fechahora_salida(fsalida, this._model.vehiculo_id)
      .subscribe((resp: any) => {
        console.log("resp", resp.registros);
        if (resp.registros > 0) {
          Swal.fire(
            "Atención",
            "Hora de Salida <strong>" +
              moment(resp.data.fechahora_salida).format("YYYY-MM-DD HH:mm") +
              "</strong> ya asignada:<br> " +
              "<strong>Vehículo:</strong> " +
              resp.data.numero +
              "<br><strong>Ruta:</strong> " +
              resp.data.rutadespacho_nombre,
            "warning"
          );
        } else {
          this.isHoraSalidaOk = true;
        }
      });
  }

  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceModel.getByPrdId(id).subscribe((resp: any) => {
      this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
      this._model = resp;
      this.frm = this.fb.group({
        planillarutadespacho_id: [this._model.planillarutadespacho_id],
        planilla_id: [this._model.planilla_id],
        rutadespacho_id: [this._model.rutadespacho_id],
        fechahora_salida: [
          moment(this._model.fechahora_salida).format("HH:mm"),
        ],
        _fechahora_salida: [
          moment(this._model.fechahora_salida).format("HH:mm"),
        ],
        _numero: [this._model.numero, Validators.required],
        _rutadespacho: [this._model.rutadespacho_nombre, Validators.required],
      });
      this.blockUI.stop();
    });
  }

  openDialog_RutasDespacho(): void {
    const dialogRef = this.dialog.open(DlgRutasDespachosComponent, {
      width: "600px",
      height: "380px",
      hasBackdrop: false,
      panelClass: "lugares-dialog",
      data: {
        isEdit: this.isEdit,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_rutasdespachos = result;
        this.frm.controls.rutadespacho_id.patchValue(result[0].rutadespacho_id);
        this.frm.controls._rutadespacho.patchValue(result[0].nombre);
        dialogSubmitSubscription.unsubscribe();
      });
  }

  check_ultima_llegada(fplanilla: string, fsalida: string) {
    console.log("fplanilla", fplanilla);
    console.log("fsalida", fsalida);
    if (fsalida.length > 0) {
      const datePlanilla = moment(fplanilla + " " + fsalida);
      console.log("datePlanilla", fplanilla);
      /* if (!this._ultimallegada.includes("No resgistra")) {
        if (moment(this._tiempo_paso) >= datePlanilla) {
          Swal.fire(
            "Atención",
            "La Hora de Salida debe ser mayor a la última llegada...",
            "error"
          );
        }
      } */
    }
  }

  get_ultima_llegada() {
    this._serviceModel
      .get_ultima_Llegada(this._model.planilla_id.toString())
      .subscribe((resp: any) => {
        if (resp) {
          this._tiempo_paso = moment(resp.tiempo_paso);
          this._ultimallegada =
            "Ruta: " +
            resp.rutadespacho_nombre +
            " | Lugar: " +
            resp.lugarcontrol_nombre +
            " | Fecha Hora: " +
            moment(resp.tiempo_paso).format("DD-MM-yyyy HH:mm");
        } else {
          this._ultimallegada = "No registra...";
        }
      });
  }

  get_ultima_ruta_asignada() {
    this._serviceModel
      .get_ultima_ruta_asignada(this._model.planilla_id.toString())
      .subscribe((resp: any) => {
        if (resp) {
          this.data_ultima_ruta = resp;
          this.frm.controls["rutadespacho_id"].patchValue(
            this.data_ultima_ruta.rutadespacho_id
          );
          this.frm.controls["_rutadespacho"].patchValue(
            this.data_ultima_ruta.rutadespacho_nombre
          );
          this._ultimaruta = this.data_ultima_ruta.rutadespacho_nombre;
        } else {
          this._ultimaruta = "";
        }
      });
  }
}
