import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { Vehiculo } from "../../models/vehiculos.model";
import { MarcasService } from "../../services/marcas.service";
import { VehiculosService } from "../../services/vehiculos.service";
import { MatDialog } from "@angular/material";
import { DlgPropietariosComponent } from "../../busquedas/dlg-propietarios.component";
import { DlgConductoresComponent } from "../../busquedas/dlg-conductores.component";
import { VehiculosValidatorComponent } from "./vehiculos-validator.component";
import { PropietariosService } from "src/app/services/propietarios.service";
import { ConductoresService } from "src/app/services/conductores.service";
import { DlgSociosComponent } from "src/app/busquedas/dlg-socios.component";
import { SociosService } from "src/app/services/socios.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-vehiculos-new",
  templateUrl: "./vehiculos-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class VehiculosNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 570;
  isLoading: boolean = false;
  frm: FormGroup;
  marcas = [];
  propietarios = [];
  conductores = [];
  socios = [];
  data_search_propietarios = [];
  data_search_conductores = [];
  data_search_socios = [];
  _model: Vehiculo;
  _propietario = "";
  isEdit: boolean = false;
  _proid: number = 0;
  _condid: number = 0;
  _socioid: number = 0;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceMarcas: MarcasService,
    private _servicePropietarios: PropietariosService,
    private _serviceConductores: ConductoresService,
    private _serviceVehiculos: VehiculosService,
    private _serviceSocios: SociosService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.frm = this.fb.group({
      vehiculo_id: [0],
      propietario_id: [0, Validators.required],
      conductor_id: [0, Validators.required],
      socio_id: [0, Validators.required],
      marca_id: [3, Validators.required],
      activo: [false],
      sucursal_contable: [0, Validators.required],
      permitir_recaudo: [false],
      placa: [
        "",
        {
          validators: [Validators.required],
          asyncValidators: [
            VehiculosValidatorComponent.existingPlacaValidator(
              this._serviceVehiculos,
              true
            ),
          ],
          updateOn: "blur",
        },
      ],
      numero: [
        "",
        {
          validators: [Validators.required],
          asyncValidators: [
            VehiculosValidatorComponent.existingNumeroValidator(
              this._serviceVehiculos,
              true
            ),
          ],
          updateOn: "blur",
        },
      ],
      modelo: ["", Validators.required],
      _propietario_nombre: ["", Validators.required],
      _socio_nombre: ["", Validators.required],
      _conductor_nombre: ["", Validators.required],
    });

    this._serviceMarcas.get_listar("1", "10").subscribe((resp: any) => {
      this.marcas = resp.data;
    });

    this._servicePropietarios.get_listar("1", "10").subscribe((resp: any) => {
      this.propietarios = resp.data;
      this.blockUI.stop();
    });

    this._serviceConductores.get_listar("1", "10").subscribe((resp: any) => {
      this.conductores = resp.data;
      this.blockUI.stop();
    });

    this._serviceSocios.get_listar("1", "10").subscribe((resp: any) => {
      this.socios = resp.data;
      this.blockUI.stop();
    });

    if (this.isEdit) {
      const id = this._model.vehiculo_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceVehiculos.getbyid(id.toString()).subscribe((resp: any) => {
      this._model = resp.data[0];
      this.frm = this.fb.group({
        vehiculo_id: [this._model.vehiculo_id],
        propietario_id: [resp.data[0].propietario_id, Validators.required],
        conductor_id: [resp.data[0].conductor_id, Validators.required],
        socio_id: [resp.data[0].socio_id, Validators.required],
        marca_id: [this._model.marca_id, Validators.required],
        activo: [this._model.activo],
        sucursal_contable: [this._model.sucursal_contable, Validators.required],
        permitir_recaudo: [this._model.permitir_recaudo],
        placa: [
          this._model.placa,
          {
            validators: [Validators.required],
            asyncValidators: [
              VehiculosValidatorComponent.existingPlacaValidator(
                this._serviceVehiculos,
                false
              ),
            ],
            updateOn: "blur",
          },
        ],
        numero: [
          this._model.numero,
          {
            validators: [Validators.required],
            asyncValidators: [
              VehiculosValidatorComponent.existingNumeroValidator(
                this._serviceVehiculos,
                false
              ),
            ],
            updateOn: "blur",
          },
        ],
        modelo: [this._model.modelo, Validators.required],
        _propietario_nombre: [resp.data[0].nombres, Validators.required],
        _socio_nombre: [resp.data[0].socio_nombres, Validators.required],
        _conductor_nombre: [
          resp.data[0].conductor_nombres,
          Validators.required,
        ],
      });
      this.blockUI.stop();
    });
  }

  openDialog_Propietarios(): void {
    const dialogRef = this.dialog.open(DlgPropietariosComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_propietarios = result;
        this.frm.controls.propietario_id.patchValue(result[0].propietario_id);
        this.frm.controls._propietario_nombre.patchValue(result[0].nombres);
        this._proid = result[0].propietario_id;
        dialogSubmitSubscription.unsubscribe();
      });
  }

  openDialog_Conductores(): void {
    const dialogRef = this.dialog.open(DlgConductoresComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_conductores = result;
        this.frm.controls.conductor_id.patchValue(result[0].conductor_id);
        this.frm.controls._conductor_nombre.patchValue(
          result[0].conductor_nombres
        );
        this._condid = result[0].conductor_id;
        dialogSubmitSubscription.unsubscribe();
      });
  }

  openDialog_Socios(): void {
    const dialogRef = this.dialog.open(DlgSociosComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_socios = result;
        this.frm.controls.socio_id.patchValue(result[0].socio_id);
        this.frm.controls._socio_nombre.patchValue(result[0].socio_nombres);
        this._socioid = result[0].socio_id;
        dialogSubmitSubscription.unsubscribe();
      });
  }

  change_numero(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls["numero"].setValue(value);
    }
  }

  change_modelo(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls["modelo"].setValue(value);
    }
  }

  change_chkactivo() {}
}
