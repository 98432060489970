import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { ReportesDespachosService } from "../reportes/services/reportes-despachos.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
  MatSelectChange,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import Swal from "sweetalert2";
import { name_mes } from "../../../config/globals";
import { TiposReportesDespachosService } from "./services/tipos-reportes-despachos.service";
import { selectPlanillaParametro } from "../planilla-parametros/store/selectors/planilla-parametro.selector";
import { cargarParametroAnioActual } from "../planilla-parametros/store/actions";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.reducers";
import { DespachosService } from "src/app/services/despachos.service";
import { VehiculosService } from "src/app/services/vehiculos.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-reportes-despachos",
  templateUrl: "./reportes-despachos.component.html",
  styles: [],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ReportesDespachosComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  @ViewChild("bus", { static: false }) numeroElement: ElementRef;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  data_rpt_conceptos = [];
  data_despachos = [];
  data_rpt_abonos_ss = [];
  data_rpt_informe_planillas = [];
  data_rpt_informe_cooperativa = [];
  data_rpt_informe_movimientos_totales = [];
  data_rpt_informe_movimientos_totales_cooperativa = [];
  data_rpt_informe_dias_trabajados = [];

  data_icontable = [];
  form: FormGroup;
  desde: string = "";
  hasta: string = "";
  tiporpt_id: number = 0;
  despacho_id: number = 0;
  tipos_rpt = [];
  cargando: boolean = false;
  btndisabled: boolean = false;
  xbus: string = "";
  xmes: string = "";
  _chkVehiculoEnabled: boolean = true;
  _chkSinCerrarEnabled: boolean = false;
  _chkEmpresaEnabled: boolean = true;
  _consultando: boolean = false;
  _tituloReporte: string = "";
  _registros: number = 0;
  _valorSS: number = 0;
  _despacho_liquidacion: string = "";
  _cerrada: number = 1;
  _vhid: number = 0;
  constructor(
    private _serviceReportesDespachos: ReportesDespachosService,
    private _serviceTiposReporteDespachos: TiposReportesDespachosService,
    private _serviceDespachos: DespachosService,
    private _serviceVehiculos: VehiculosService,
    private store: Store<AppState>,
    private fb: FormBuilder
  ) {
    /*  this.store.select("planillaparametro").subscribe((resp) => {
      console.log("datos$", resp);
    }); */
    this.form = this.fb.group({
      fecha_desde: [new Date(), Validators.required],
      fecha_hasta: [new Date(), Validators.required],
      tiporeportedespacho_id: [1, Validators.required],
      numero: [""],
      chkvehiculo: [false],
      chkempresa: [false],
      chksincerrar: [false],
      vehiculo_id: [0],
      despacho_id: [1, Validators.required],
    });
  }

  async ngOnInit() {
    this.store.dispatch(cargarParametroAnioActual({ id: "2024" }));

    /* setTimeout(() => {
      this.store.select(selectPlanillaParametro).subscribe((resp) => {
        this._valorSS = resp.item[0].valor_ss_conductor;
      });
    }, 200); */

    this._serviceTiposReporteDespachos.getLista().subscribe((resp: any) => {
      this.tipos_rpt = resp.data;
      this.form.controls["tiporeportedespacho_id"].patchValue(1);
    });
    this._serviceDespachos.get_listar("1", "100").subscribe((resp: any) => {
      this.data_despachos = resp.data;
      this.form.controls["despacho_id"].patchValue(2);
      this._despacho_liquidacion = this.data_despachos[0].nombre;
    });
  }

  get fdata() {
    return this.form.controls;
  }

  consultar() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.desde = moment(this.form.controls.fecha_desde.value).format(
      "YYYY-MM-DD 00:00"
    );
    this.hasta = moment(this.form.controls.fecha_hasta.value).format(
      "YYYY-MM-DD 23:59"
    );

    this.xmes =
      name_mes[
        parseInt(moment(this.form.controls.fecha_desde.value).format("MM")) - 1
      ];

    this._cerrada = 1;
    if (this.form.controls["chksincerrar"].value == true) {
      this._cerrada = 0;
    }

    this.data_rpt_conceptos = [];
    this.data_rpt_abonos_ss = [];
    this.data_rpt_informe_planillas = [];
    this.data_rpt_informe_cooperativa = [];
    this.data_rpt_informe_movimientos_totales = [];
    this.data_rpt_informe_movimientos_totales_cooperativa = [];
    this.data_rpt_informe_dias_trabajados = [];
    this.data_icontable = [];
    this._serviceReportesDespachos.EventDatosInformePlanillas$.emit([]);
    this._serviceReportesDespachos.EventDatosInformeCooperativa$.emit([]);
    this._serviceReportesDespachos.EventDatosInformeMovimientosTotales$.emit(
      []
    );
    this._serviceReportesDespachos.EventDatosInformeMovimientosTotalesCooperativa$.emit(
      []
    );

    this._serviceReportesDespachos.EventDatosInformeDiasTrabajados$.emit([]);

    this._serviceReportesDespachos.EventDatosAhorros$.emit([]);
    this._serviceReportesDespachos.FechaDesde$.emit(this.desde);
    this._serviceReportesDespachos.FechaHasta$.emit(this.hasta);
    this._consultando = true;

    switch (this.tiporpt_id) {
      case 1:
        this._tituloReporte = "INFORME DE AHORRO DE PROPIETARIOS";
        break;
      case 3:
        this._tituloReporte = "INFORME DE ABONO SS";
        break;
      case 4:
        this._tituloReporte = "INFORME DE ADICIONAL SS";
        break;
      case 5:
        this._tituloReporte = "INFORME DE ABONOS SS POR COBRAR";
        break;
      case 6:
        this._tituloReporte = "RELACION DIARIA FONDOS EMPRESA";
        break;
      case 7:
        this._tituloReporte = "RELACION DIARIA COOPERATIVA";
        break;
      case 8:
        this._tituloReporte = "INTERFAZ CONTABLE 1";
        break;
      case 9:
        this._tituloReporte = "INTERFAZ CONTABLE 2";
        break;
      case 10:
        this._tituloReporte = "INFORME MOVIMIENTOS TOTALES EMPRESA";
        break;
      case 11:
        this._tituloReporte = "INFORME MOVIMIENTOS TOTALES COOPERATIVA";
        break;
      case 11:
        this._tituloReporte = "INFORME DIAS TRABAJADOS";
        break;
    }

    if (this.form.controls.chkvehiculo.value === true) {
      if (this.form.controls.numero.value === "") {
        Swal.fire("Precaución", "Número de Vehículo no válido...", "warning");
        this.blockUI.stop();
        return false;
      }
    }

    if (
      this.tiporpt_id === 1 ||
      this.tiporpt_id === 3 ||
      this.tiporpt_id === 4
    ) {
      if (
        this.form.controls.chkvehiculo.value === false &&
        this.form.controls.chkempresa.value === false
      ) {
        this._chkVehiculoEnabled = false;
        this._chkEmpresaEnabled = false;
        this.xbus = "-1";
        this._serviceReportesDespachos.mes$.emit(this.xmes);
        this._serviceReportesDespachos.bus$.emit(this.xbus);
        this._serviceReportesDespachos
          .get_concepto_general(this.desde, this.hasta, this._cerrada)
          .subscribe((resp: any) => {
            this.data_rpt_conceptos = resp.data;
            this._serviceReportesDespachos.EventDatosAhorros$.emit(resp.data);
            this.blockUI.stop();
            if (resp.data.length === 0) {
              Swal.fire(
                "Atención",
                "No se encontraron datos para esta consulta...",
                "info"
              );
            }
          });
      }

      if (this.form.controls.chkvehiculo.value === true) {
        if (this.form.controls.numero.value === "") {
          Swal.fire("Precaución", "Número de Vehículo no válido...", "warning");
          this.blockUI.stop();
          return false;
        } else {
          this._chkVehiculoEnabled = true;
          this.xbus = this.form.controls.numero.value;
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);
          this._serviceReportesDespachos
            .get_conceptoByBus(
              this.desde,
              this.hasta,
              this.form.controls.numero.value,
              this._cerrada
            )
            .subscribe((resp: any) => {
              this._serviceReportesDespachos.EventDatosAhorros$.emit(resp.data);
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }
      }
    }

    if (this.tiporpt_id === 5) {
      if (
        this.form.controls.chkvehiculo.value === false &&
        this.form.controls.chkempresa.value === false
      ) {
        this._chkVehiculoEnabled = false;
        this._chkEmpresaEnabled = false;
        this.xbus = "-1";
        this._serviceReportesDespachos.mes$.emit(this.xmes);
        this._serviceReportesDespachos.bus$.emit(this.xbus);
        this._serviceReportesDespachos
          .get_abonos_ss_general(this.desde, this.hasta)
          .subscribe((resp: any) => {
            this.data_rpt_abonos_ss = resp.data;
            this._serviceReportesDespachos.EventDatosAbonosSS$.emit(resp.data);
            this.blockUI.stop();
            if (resp.data.length === 0) {
              Swal.fire(
                "Atención",
                "No se encontraron datos para esta consulta...",
                "info"
              );
            }
          });
      }

      if (this.form.controls.chkvehiculo.value === true) {
        if (this.form.controls.numero.value === "") {
          Swal.fire("Precaución", "Número de Vehículo no válido...", "warning");
          this.blockUI.stop();
          return false;
        } else {
          this._chkVehiculoEnabled = true;
          this.xbus = this.form.controls.numero.value;
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);
          this._serviceReportesDespachos
            .get_abonos_ssByBus(
              this.desde,
              this.hasta,
              this.form.controls.numero.value
            )
            .subscribe((resp: any) => {
              this.data_rpt_abonos_ss = resp.data;
              this._serviceReportesDespachos.EventDatosAbonosSS$.emit(
                resp.data
              );
              this._serviceReportesDespachos.totalabonosss$.emit(
                resp.data[0].total_abono_ss
              );
              this._serviceReportesDespachos.diascalendario$.emit(
                resp.registros
              );
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }
      }
    }

    if (this.tiporpt_id === 6) {
      if (this.despacho_id == 3) {
        //Todos los despachos

        if (
          this.form.controls.chkvehiculo.value === false &&
          this.form.controls.chkempresa.value === false
        ) {
          this._chkVehiculoEnabled = false;
          this._chkEmpresaEnabled = false;
          this.xbus = "-1";
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);

          this._serviceReportesDespachos
            .get_informe_para_cobro_all(this.desde, this.hasta, this._cerrada)
            .subscribe((resp: any) => {
              this.data_rpt_informe_planillas = resp.data;
              this._serviceReportesDespachos.EventDatosInformePlanillas$.emit(
                resp.data
              );
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }
      } else {
        if (
          this.form.controls.chkvehiculo.value === false &&
          this.form.controls.chkempresa.value === false
        ) {
          this._chkVehiculoEnabled = false;
          this._chkEmpresaEnabled = false;
          this.xbus = "-1";
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);
          this._serviceReportesDespachos
            .get_informe_para_cobrobydespachoid(
              this.desde,
              this.hasta,
              this.despacho_id,
              this._cerrada
            )
            .subscribe((resp: any) => {
              this.data_rpt_informe_planillas = resp.data;
              this._serviceReportesDespachos.EventDatosInformePlanillas$.emit(
                resp.data
              );
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        } else {
        }
      }

      if (
        this.form.controls.chkvehiculo.value === true &&
        this.form.controls.chkempresa.value === false
      ) {
        if (this.despacho_id == 3) {
          this._chkVehiculoEnabled = true;
          this._chkEmpresaEnabled = false;
          this.xbus = "-1";
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);
          this._serviceReportesDespachos
            .get_informe_para_cobrobyBus(
              this.desde,
              this.hasta,
              this.form.controls.numero.value,
              this._cerrada
            )
            .subscribe((resp: any) => {
              this.data_rpt_informe_planillas = resp.data;
              this._serviceReportesDespachos.EventDatosInformePlanillas$.emit(
                resp.data
              );
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        } else {
          this._chkVehiculoEnabled = true;
          this._chkEmpresaEnabled = false;
          // this.xbus = "-1";
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);
          this.xbus = this.form.controls.numero.value;
          this._serviceReportesDespachos
            .get_informe_para_cobrobydespachoidAndBus(
              this.desde,
              this.hasta,
              this.despacho_id,
              this.form.controls.numero.value,
              this._cerrada
            )
            .subscribe((resp: any) => {
              this.data_rpt_informe_planillas = resp.data;
              this._serviceReportesDespachos.EventDatosInformePlanillas$.emit(
                resp.data
              );
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }
      }
    }

    //Cooperativa
    if (this.tiporpt_id === 7) {
      if (this.despacho_id == 3) {
        if (
          this.form.controls.chkvehiculo.value === false &&
          this.form.controls.chkempresa.value === false
        ) {
          //ok
          this._chkVehiculoEnabled = false;
          this._chkEmpresaEnabled = false;
          this.xbus = "-1";
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);
          this._serviceReportesDespachos
            .get_informe_cooperativa_all(this.desde, this.hasta, this._cerrada)
            .subscribe((resp: any) => {
              this.data_rpt_informe_cooperativa = resp.data;
              this._serviceReportesDespachos.EventDatosInformeCooperativa$.emit(
                resp.data
              );
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }

        if (
          this.form.controls.chkvehiculo.value === true &&
          this.form.controls.chkempresa.value === false
        ) {
          //ok
          this._chkVehiculoEnabled = true;
          this._chkEmpresaEnabled = false;
          this.xbus = "-1";
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);

          this._serviceReportesDespachos
            .get_informe_cooperativabybus(
              this.desde,
              this.hasta,
              this._cerrada,
              this.form.controls.numero.value
            )
            .subscribe((resp: any) => {
              this.data_rpt_informe_cooperativa = resp.data;
              this._serviceReportesDespachos.EventDatosInformeCooperativa$.emit(
                resp.data
              );
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }
      } else {
        //Por Despacho
        if (
          this.form.controls.chkvehiculo.value === false &&
          this.form.controls.chkempresa.value === false
        ) {
          this._chkVehiculoEnabled = false;
          this._chkEmpresaEnabled = false;
          this.xbus = "-1";
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);
          this._serviceReportesDespachos
            .get_informe_cooperativabydespachoid(
              this.desde,
              this.hasta,
              this.despacho_id,
              this._cerrada
            )
            .subscribe((resp: any) => {
              this.data_rpt_informe_cooperativa = resp.data;
              this._serviceReportesDespachos.EventDatosInformeCooperativa$.emit(
                resp.data
              );
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }

        if (
          this.form.controls.chkvehiculo.value === true &&
          this.form.controls.chkempresa.value === false
        ) {
          this._chkVehiculoEnabled = true;
          this._chkEmpresaEnabled = false;
          this.xbus = "-1";
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);
          this._serviceReportesDespachos
            .get_informe_cooperativabydespachoidAndbus(
              this.desde,
              this.hasta,
              this.despacho_id,
              this.form.controls.numero.value,
              this._cerrada
            )
            .subscribe((resp: any) => {
              this.data_rpt_informe_cooperativa = resp.data;
              this._serviceReportesDespachos.EventDatosInformeCooperativa$.emit(
                resp.data
              );
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }
      }
    }

    //Informe Movimientos Totales
    if (this.tiporpt_id === 10) {
      if (this.despacho_id == 3) {
        if (
          this.form.controls.chkvehiculo.value === false &&
          this.form.controls.chkempresa.value === false
        ) {
          //ok
          this._chkVehiculoEnabled = false;
          this._chkEmpresaEnabled = false;
          this.xbus = "-1";
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);
          this._serviceReportesDespachos
            .get_informe_movimientos_totales_all(
              this.desde,
              this.hasta,
              this._cerrada
            )
            .subscribe((resp: any) => {
              this.data_rpt_informe_movimientos_totales = resp.data;
              this._serviceReportesDespachos.EventDatosInformeMovimientosTotales$.emit(
                resp.data
              );
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }

        if (
          this.form.controls.chkvehiculo.value === true &&
          this.form.controls.chkempresa.value === false
        ) {
          this.blockUI.stop();
          Swal.fire("Atención", "Opción no disponible...", "info");
        }
      } else {
        //Por Despacho
        if (
          this.form.controls.chkvehiculo.value === false &&
          this.form.controls.chkempresa.value === false
        ) {
          this.blockUI.stop();
          Swal.fire("Atención", "Opción no disponible...", "info");
        }

        if (
          this.form.controls.chkvehiculo.value === true &&
          this.form.controls.chkempresa.value === false
        ) {
          this.blockUI.stop();
          Swal.fire("Atención", "Opción no disponible...", "info");
        }
      }
    }

    //Informe Movimientos Totales Cooperativa
    if (this.tiporpt_id === 11) {
      if (this.despacho_id == 3) {
        if (
          this.form.controls.chkvehiculo.value === false &&
          this.form.controls.chkempresa.value === false
        ) {
          //ok
          this._chkVehiculoEnabled = false;
          this._chkEmpresaEnabled = false;
          this.xbus = "-1";
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);
          this._serviceReportesDespachos
            .get_informe_movimientos_totales_cooperativa_all(
              this.desde,
              this.hasta,
              this._cerrada
            )
            .subscribe((resp: any) => {
              this.data_rpt_informe_movimientos_totales_cooperativa = resp.data;
              console.log(
                this.data_rpt_informe_movimientos_totales_cooperativa
              );
              this._serviceReportesDespachos.EventDatosInformeMovimientosTotalesCooperativa$.emit(
                resp.data
              );
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }

        if (
          this.form.controls.chkvehiculo.value === true &&
          this.form.controls.chkempresa.value === false
        ) {
          this.blockUI.stop();
          Swal.fire("Atención", "Opción no disponible...", "info");
        }
      } else {
        //Por Despacho
        if (
          this.form.controls.chkvehiculo.value === false &&
          this.form.controls.chkempresa.value === false
        ) {
          this.blockUI.stop();
          Swal.fire("Atención", "Opción no disponible...", "info");
        }

        if (
          this.form.controls.chkvehiculo.value === true &&
          this.form.controls.chkempresa.value === false
        ) {
          this.blockUI.stop();
          Swal.fire("Atención", "Opción no disponible...", "info");
        }
      }
    }

    //Informe Dias Trabajados
    if (this.tiporpt_id === 12) {
      if (this.despacho_id == 3) {
        if (
          this.form.controls.chkvehiculo.value === false &&
          this.form.controls.chkempresa.value === false
        ) {
          //ok
          this._chkVehiculoEnabled = false;
          this._chkEmpresaEnabled = false;
          this.xbus = "-1";
          this._serviceReportesDespachos.mes$.emit(this.xmes);
          this._serviceReportesDespachos.bus$.emit(this.xbus);
          this.blockUI.stop();
          Swal.fire("Atención", "Opción no disponible...", "info");
        }

        if (
          this.form.controls.chkvehiculo.value === true &&
          this.form.controls.chkempresa.value === false
        ) {
          this._chkVehiculoEnabled = true;
          this._chkEmpresaEnabled = false;
          this._serviceVehiculos
            .getbynumero(this.form.controls.numero.value)
            .subscribe((resp: any) => {
              if (resp.data[0].placa) {
                this._vhid = resp.data[0].vehiculo_id;
                this._serviceReportesDespachos
                  .get_dias_trabajados(this._vhid, this.desde, this.hasta)
                  .subscribe((resp: any) => {
                    this.data_rpt_informe_dias_trabajados = resp.data;
                    console.log(this.data_rpt_informe_dias_trabajados);
                    this._serviceReportesDespachos.EventDatosInformeDiasTrabajados$.emit(
                      resp.data
                    );
                    this.blockUI.stop();
                    if (resp.data.length === 0) {
                      Swal.fire(
                        "Atención",
                        "No se encontraron datos para esta consulta...",
                        "info"
                      );
                    }
                  });
              }
            });
        }
      } else {
        //Por Despacho
        if (
          this.form.controls.chkvehiculo.value === false &&
          this.form.controls.chkempresa.value === false
        ) {
          this.blockUI.stop();
          Swal.fire("Atención", "Opción no disponible...", "info");
        }

        if (
          this.form.controls.chkvehiculo.value === true &&
          this.form.controls.chkempresa.value === false
        ) {
          this.blockUI.stop();
          Swal.fire("Atención", "Opción no disponible...", "info");
        }
      }
    }

    if (this.tiporpt_id === 8 || this.tiporpt_id === 9) {
      let intr = 1;

      if (this.tiporpt_id === 9) {
        intr = 2;
      }

      this._serviceReportesDespachos
        .get_icontable(this.desde, this.hasta, intr)
        .subscribe((resp: any) => {
          this.data_icontable = resp.data;
          let filename = `INTERFACE${intr}_${moment(
            new Date(this.desde)
          ).format("YYYYMMDD")}`;

          this._serviceReportesDespachos.downloadFile_IContable(
            this.data_icontable,
            filename
          );

          this.blockUI.stop();
          if (this.data_icontable.length === 0) {
            Swal.fire(
              "Atención",
              "No se encontraron datos para esta consulta...",
              "info"
            );
          }
        });
    }
  }

  change_bus(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.form.controls["numero"].setValue(value);
    }
  }
  change_chk() {
    if (this.form.controls["chkvehiculo"].value) {
      this.numeroElement.nativeElement.focus();
      this.form.controls["chkempresa"].patchValue(false);
    }
  }
  change_chkEmpresa(e) {
    if (this.form.controls["chkempresa"].value) {
      this.form.controls["chkvehiculo"].patchValue(false);
    }
  }

  change_chkSincerrar() {}

  change_opcion() {
    this.data_rpt_conceptos = [];
    this.data_rpt_abonos_ss = [];
    this.data_rpt_informe_planillas = [];
    setTimeout(() => {
      this.form.controls["despacho_id"].patchValue(2);
    }, 100);
  }

  change_despacho(event: MatSelectChange) {
    //const selectedText = (event.source.selected as MatOption).viewValue;
    this._despacho_liquidacion = (event.source.selected as MatOption).viewValue;
  }
}
