import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Planilla } from "src/app/models/planillas.model";
import { EmpresaService } from "src/app/services/empresa.service";
import { Empresa } from "src/app/models/empresa.model";
import { QRCodeElementType, QRCodeErrorCorrectionLevel } from "angularx-qrcode";
import { PlanillasService } from "../services/planillas.service";
import { PlanillasLiquidacionService } from "../../planillas-liquidacion/services/planillas-liquidacion.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
  selector: "app-resumen-liquidacion",
  templateUrl: "./resumen-liquidacion.component.html",
  styleUrls: ["./resumen-liquidacion.component.css"],
})
export class ResumenLiquidacionComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  @ViewChild("txtsearch", { static: false })
  txtsearch: ElementRef<HTMLInputElement>;
  form: FormGroup;
  _search: any = "";
  data_resumen = [];
  groupedData: any[];
  data_planilla: Planilla;
  data_empresa: Empresa;
  anio: number = new Date().getFullYear();
  data: any;

  public allowEmptyString: boolean;
  public alt: string;
  public ariaLabel: string;
  public colorDark: string;
  public colorLight: string;
  public cssClass: string;
  public elementType: QRCodeElementType;
  public errorCorrectionLevel: QRCodeErrorCorrectionLevel;
  public imageSrc?: string;
  public imageHeight?: number;
  public imageWidth?: number;
  public margin: number;
  public qrdata: string;
  public scale: number;
  public title: string;
  public width: number;
  public showImage: boolean;

  constructor(
    private fb: FormBuilder,
    private _servicePlanillas: PlanillasService,
    private _servicePlanillaLiq: PlanillasLiquidacionService,
    private _serviceEmpresa: EmpresaService
  ) {}

  ngAfterViewInit(): void {
    this.txtsearch.nativeElement.focus();
  }

  ngOnInit() {
    this._serviceEmpresa.getbyid("1").subscribe((resp: any) => {
      this.data_empresa = resp.data[0];
    });
    this.form = this.fb.group({
      search: ["", Validators.required],
    });
  }

  get_planilla(value: any) {
    this._search = value;

    if (value.length > 0) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      this._servicePlanillas.getRegistradaById(value).subscribe((resp: any) => {
        this.data_planilla = resp.data;
        this.qrdata = this.data_planilla.planilla_id.toString();

        this._servicePlanillaLiq
          .get_Resumen_Liquidacion_Planilla(value)
          .subscribe((resp: any) => {
            this.data_resumen = resp;
            this.groupData();
            this.blockUI.stop();
            this.form.reset();
          });
      });
    }
  }

  groupData(): void {
    const groups = this.data_resumen.reduce((result, ruta) => {
      const group = result.find(
        (item) => item.ruta === ruta.group_ruta_horasalida
      );
      if (group) {
        group.lugares.push(ruta);
      } else {
        result.push({
          ruta: ruta.group_ruta_horasalida,
          lugares: [ruta],
        });
      }
      return result;
    }, []);
    this.groupedData = groups;
    let x = 0;
    this.groupedData.forEach((item) => {
      let acum = 0;
      item.lugares.forEach((element) => {
        acum = acum + element.retardo_int;
        element.minutos_acumulado = acum;
      });
      x++;
    });
  }

  get_Total_Minutos() {
    let total: number = this.data_resumen.reduce(
      (acum: number, items: { retardo_int: number }) => {
        return acum + +items.retardo_int;
      },
      0
    );
    return total;
  }

  get_Total_Minutos_Sancion() {
    let total: number = this.data_resumen.reduce(
      (acum: number, items: { minutos_sancionados: number }) => {
        return acum + +items.minutos_sancionados;
      },
      0
    );
    return total;
  }

  getClass(value, value2) {
    if (value > 0 || value2 > 0) return "numberRed";
  }
}
