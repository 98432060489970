import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { URL_API, URL_POS_PRINTER } from "src/app/config/config";
import { PosPrinter } from "src/app/models/pos-printer.model";

@Injectable({
  providedIn: "root",
})
export class PosPrinterService {
  private url = URL_POS_PRINTER;
  private url_api = URL_API;

  constructor(private http: HttpClient) {}

  imprimir_tiquete(
    printName: string,
    ip_address: string,
    tipo_connection: string,
    model: any
  ) {
    return this.http.post(
      `${this.url}/print/tiquete/${printName}/${ip_address}/${tipo_connection}`,
      model
    );
  }

  imprimir_tiquete_detalles(
    printName: string,
    ip_address: string,
    tipo_connection: string,
    model: any
  ) {
    console.log("xxxxx", printName);
    return this.http.post(
      `${this.url}/print/tiquete_detalles/${printName}/${ip_address}/${tipo_connection}`,
      model
    );
  }

  get_listar_impresoras() {
    return this.http.get(`${this.url}/print/list`);
  }

  probar_conexion(printName: string) {
    return this.http.get(`${this.url}/print/checkconnect/${printName}`);
  }

  get_printer() {
    return this.http.get(`${this.url_api}/pos_printer/get_printer`);
  }

  establecer_predeterminada(model: PosPrinter) {
    return this.http.post(`${this.url_api}/pos_printer/set_printer`, model);
  }

  update_predeterminada(model: PosPrinter) {
    return this.http.put(
      `${this.url_api}/pos_printer/update/${model.posprinter_id}`,
      model
    );
  }
}
