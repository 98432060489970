import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-dashboard-retardos",
  templateUrl: "./dashboard-retardos.component.html",
  styleUrls: ["./dashboard-retardos.component.css"],
})
export class DashboardRetardosComponent implements OnInit {
  @Input() data: { name: string; value: number }[] = [];

  // Opciones de gráfico
  view: [number, number] = [1700, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = "Vehículos";
  showYAxisLabel = true;
  yAxisLabel = "Minutos de Retardo";

  colorScheme = {
    domain: ["#5AA454", "#A10A28", "#C7B42C", "#AAAAAA"],
  };

  constructor() {}

  ngOnInit() {
    console.log("Data Retardos", this.data);
  }
}
