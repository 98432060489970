import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PosPrinterService } from "src/app/services/pos-printer/pos-printer.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-pos-printer-config",
  templateUrl: "./pos-printer-config.component.html",
  styleUrls: ["./pos-printer-config.component.css"],
})
export class PosPrinterConfigComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  list_printers: any = [];
  data_printer: any = [];
  isEdit: boolean = false;
  tipo_conexion = [
    { tipo_connection: "printer:", name_connection: "USB" },
    { tipo_connection: "tcp:%2F%2F", name_connection: "Red Local" },
  ];

  form: FormGroup;
  constructor(
    private _servicePosPrinter: PosPrinterService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      posprinter_id: [0],
      printer_name: ["", Validators.required],
      ip_address: ["", Validators.required],
      tipo_connection: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.get_listar_impresoras();
    this.get_printer();
  }

  get fdata() {
    return this.form.controls;
  }

  async get_listar_impresoras() {
    this._servicePosPrinter.get_listar_impresoras().subscribe((resp: any) => {
      this.list_printers = resp.data;
    });
  }

  change_printer() {}

  establecer_predeterminada() {
    console.log("form", this.form.value);

    if (this.form.valid) {
      if (this.isEdit) {
        this._servicePosPrinter
          .update_predeterminada(this.form.value)
          .subscribe((resp: any) => {
            this.get_printer();
          });
      } else {
        this._servicePosPrinter
          .establecer_predeterminada(this.form.value)
          .subscribe((resp: any) => {
            this.get_printer();
          });
      }
    } else {
      Swal.fire("Precaución", "Los datos no son válidos...", "warning");
      return false;
    }
  }
  get_printer() {
    this._servicePosPrinter.get_printer().subscribe((resp: any) => {
      this.data_printer = resp.data;
      if (this.data_printer.length > 0) {
        this.isEdit = true;
        this.form = this.fb.group({
          posprinter_id: [this.data_printer[0].posprinter_id],
          printer_name: [
            this.data_printer[0].printer_name,
            Validators.required,
          ],
          ip_address: [this.data_printer[0].ip_address, Validators.required],
          tipo_connection: [
            this.data_printer[0].tipo_connection,
            Validators.required,
          ],
        });
      } else {
        this.isEdit = false;
        this.form = this.fb.group({
          posprinter_id: [0],
          printer_name: ["", Validators.required],
          ip_address: ["", Validators.required],
          tipo_connection: ["", Validators.required],
        });
      }
    });
  }

  probar_conexion() {
    this._servicePosPrinter
      .probar_conexion(this.form.controls["printer_name"].value)
      .subscribe((resp: any) => {
        console.log("conexion: ", resp.ok);
        if (resp.ok) {
          Swal.fire(
            "Información",
            "Conexión establecida con la impresora...",
            "success"
          );
        } else {
        }
      });
  }
}
