import { BrowserModule, Title } from "@angular/platform-browser";
import {
  NgbModule,
  NgbTimepickerModule,
  NgbTooltipConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { AppRoutingModule } from "./app.routing";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { TopMenuComponent } from "./components/top-menu/top-menu.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SidebarRightComponent } from "./components/sidebar-right/sidebar-right.component";
import { HeaderComponent } from "./components/header/header.component";
import { ClientesComponent } from "./pages/clientes/clientes.component";
import { EmpresaComponent } from "./pages/empresa/empresa.component";
import { FloatSubMenuComponent } from "./components/float-sub-menu/float-sub-menu.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { PanelComponent } from "./components/panel/panel.component";
import { PaginadorComponent } from "./paginador/paginador.component";
import {
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatSelectModule,
  MatSliderModule,
  MatToolbarModule,
} from "@angular/material";
import { ClientesNewComponent } from "./pages/clientes/clientes-new.component";
import { VehiculosComponent } from "./pages/vehiculos/vehiculos.component";
import { VehiculosNewComponent } from "./pages/vehiculos/vehiculos-new.component";
import { MarcasComponent } from "./pages/marcas/marcas.component";
import { MarcasNewComponent } from "./pages/marcas/marcas-new.component";
import { DlgClientesComponent } from "./busquedas/dlg-clientes.component";
import { TarifasDescuentosComponent } from "./pages/tarifas-descuentos/tarifas-descuentos.component";
import { TarifasDescuentosNewComponent } from "./pages/tarifas-descuentos/tarifas-descuentos-new.component";
import { VentasComponent } from "./pages/ventas/ventas.component";
import { VentasNewComponent } from "./pages/ventas/ventas-new.component";
import { DlgVehiculosComponent } from "./busquedas/dlg-vehiculos.component";
import { DlgProductosComponent } from "./busquedas/dlg-productos.component";
import { ProductosComponent } from "./pages/productos/productos.component";
import { ProductosNewComponent } from "./pages/productos/productos-new.component";
import { EmpresaNewComponent } from "./pages/empresa/empresa-new.component";
import { NgxPrintModule } from "ngx-print";
import { RptHeaderComponent } from "./components/rpt-header/rpt-header.component";
import { LoginComponent } from "./auth/login.component";
import { UsersComponent } from "./auth/users.component";
import { UsersNewComponent } from "./auth/users-new.component";
import { RegisterComponent } from "./auth/register.component";
import { AuthValidatorComponent } from "./auth/auth-validator.component";
import { AuthorizatedGuard } from "./auth/authorizated-guard";
import { HomeComponent } from "./components/home/home.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { TrendModule } from "ngx-trend";
import { RetirosComponent } from "./pages/retiros/retiros.component";
import { RetirosNewComponent } from "./pages/retiros/retiros-new.component";
import { DlgDescuentosComponent } from "./busquedas/dlg-descuentos.component";
import { VentasValidatorComponent } from "./pages/ventas/ventas-validator.component";
import { ConsultaRetirosComponent } from "./pages/retiros/consulta-retiros.component";
import { PropietariosNewComponent } from "./pages/propietarios/propietarios-new.component";
import { PropietariosComponent } from "./pages/propietarios/propietarios.component";
import { DlgPropietariosComponent } from "./busquedas/dlg-propietarios.component";
import { PropietariosValidatorComponent } from "./pages/propietarios/propietarios-validator.component";
import { RecaudosComponent } from "./pages/recaudos/recaudos.component";
import { RecaudosNewComponent } from "./pages/recaudos/recaudos-new.component";
import { DlgRutasComponent } from "./busquedas/dlg-rutas.component";
import { ReportesPageComponent } from "./pages/reportes/reportes-page.component";
import { ListReportesPageComponent } from "./pages/reportes/list-reportes-page/list-reportes-page.component";
import { RptRecaudosComponent } from "./pages/reportes/rpt/rpt-recaudos.component";
import { RutasComponent } from "./pages/rutas/rutas.component";
import { RutasNewComponent } from "./pages/rutas/rutas-new.component";
import { GruposComponent } from "./pages/grupos/grupos.component";
import { GruposNewComponent } from "./pages/grupos/grupos-new.component";
import { DlgRetirosComponent } from "./busquedas/dlg-retiros.component";
import { RptRetirosComponent } from "./pages/reportes/rpt/rpt-retiros.component";
import { RetirosListComponent } from "./pages/retiros/retiros-list.component";
import { RetirosListResponseComponent } from "./pages/retiros/retiros-list-response.component";
import { BlockTemplateComponent } from "./templates/block-template.component";
import { BlockUIModule } from "ng-block-ui";
import { RptArqueoComponent } from "./pages/reportes/rpt/rpt-arqueo.component";
import { UsersEditComponent } from "./auth/users-edit.component";
import { UsersChangePasswordComponent } from "./auth/users-change-password.component";
import { AdminDashboardComponent } from "./pages/dashboard/admin-dashboard.component";
import { UserDashboardComponent } from "./pages/dashboard/user-dashboard.component";
import { StoreModule } from "@ngrx/store";
import { appReducers } from "./app.reducers";
import { EffectsModule } from "@ngrx/effects";
import { EffectsArray } from "./store/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "src/environments/environment";
import { MovimientosNewComponent } from "./pages/movimientos/movimientos-new.component";
import { MovimientosListComponent } from "./pages/movimientos/movimientos-list.component";
import { MovimientosListResponseComponent } from "./pages/movimientos/movimientos-list-response.component";
import { MovimientosComponent } from "./pages/movimientos/movimientos.component";
import { ConsultaMovimientosComponent } from "./pages/movimientos/consulta-movimientos.component";
import { RptConsignacionesComponent } from "./pages/reportes/rpt/rpt-movimientos.component";
import { BancosComponent } from "./pages/bancos/bancos.component";
import { BancosNewComponent } from "./pages/bancos/bancos-new.component";
import { TiposCuentasBancariasComponent } from "./pages/tipos_cuentas_bancarias/tipos_cuentas_bancarias.component";
import { TiposCuentasBancariasNewComponent } from "./pages/tipos_cuentas_bancarias/tipos_cuentas_bancarias-new.component";
import { DespachosModule } from "./despachos/despachos.module";
import { RptTotalesRecaudosComponent } from "./pages/reportes/rpt/rpt-totales-recaudos.component";
import { ConductoresNewComponent } from "./pages/conductores/conductores-new.component";
import { ConductoresComponent } from "./pages/conductores/conductores.component";
import { ConductoresValidatorComponent } from "./pages/conductores/conductores-validator.component";
import { DlgConductoresComponent } from "./busquedas/dlg-conductores.component";
import { VehiculosConductoresComponent } from "./pages/vehiculos-conductores/vehiculos-conductores.component";
import { VehiculosConductoresNewComponent } from "./pages/vehiculos-conductores/vehiculos-conductores-new.component";
import { DlgRutasDespachosComponent } from "./busquedas/dlg-rutas-despachos.component";
import { DlgLugaresControlComponent } from "./busquedas/dlg-lugares-control.component";
import { DlgSancionesComponent } from "./busquedas/dlg-sanciones.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { AccessDeniedComponent } from "./components/access-denied/access-denied.component";
import { UnauthorizedComponent } from "./auth/unauthorized/unauthorized.component";
import { RolesComponent } from "./auth/roles/roles.component";
import { RolesNewComponent } from "./auth/roles/roles-new.component";
import { ListRolesComponent } from "./auth/roles/list-roles.component";
import { InterceptorService } from "./auth/interceptor/interceptor.service";
import { DlgRelevantesComponent } from "./busquedas/dlg-relevantes.component";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { CooperativaModule } from "./cooperativa/cooperativa.module";
import { SociosNewComponent } from "./pages/socios/socios-new.component";
import { SociosComponent } from "./pages/socios/socios.component";
import { DlgSociosComponent } from "./busquedas/dlg-socios.component";
import { DlgUsersComponent } from "./busquedas/dlg-users.component";
import { DlgDespachosComponent } from "./busquedas/dlg-despachos.component";
import { UsersDespachosComponent } from "./pages/users-despachos/users-despachos.component";
import { UsersDespachosNewComponent } from "./pages/users-despachos/users-despachos-new.component";
import { QRCodeModule } from "angularx-qrcode";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { DlgPlanillasComponent } from "./busquedas/dlg-planillas.component";
import { EnviarMensajeComponent } from "./sms/enviar-mensaje/enviar-mensaje.component";
import { PosPrintModule } from "./pos-print/pos-print.module";
// import { MatKeyboardModule } from "angular-onscreen-material-keyboard";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

/* const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
}; */

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    TopMenuComponent,
    SidebarComponent,
    SidebarRightComponent,
    HeaderComponent,
    ClientesComponent,
    EmpresaComponent,
    FloatSubMenuComponent,
    PanelComponent,
    PaginadorComponent,
    ClientesNewComponent,
    PropietariosNewComponent,
    PropietariosComponent,
    ConductoresNewComponent,
    ConductoresComponent,
    SociosNewComponent,
    SociosComponent,
    VehiculosConductoresComponent,
    VehiculosComponent,
    VehiculosNewComponent,
    VehiculosConductoresNewComponent,
    RecaudosComponent,
    RecaudosNewComponent,
    MarcasComponent,
    MarcasNewComponent,
    TiposCuentasBancariasComponent,
    TiposCuentasBancariasNewComponent,
    DlgClientesComponent,
    DlgPropietariosComponent,
    DlgPlanillasComponent,
    DlgConductoresComponent,
    DlgSociosComponent,
    DlgRelevantesComponent,
    DlgRutasComponent,
    DlgRetirosComponent,
    DlgLugaresControlComponent,
    TarifasDescuentosComponent,
    TarifasDescuentosNewComponent,
    VentasComponent,
    VentasNewComponent,
    DlgVehiculosComponent,
    DlgRutasDespachosComponent,
    DlgSancionesComponent,
    DlgProductosComponent,
    DlgDescuentosComponent,
    DlgUsersComponent,
    DlgDespachosComponent,
    ProductosComponent,
    ProductosNewComponent,
    EmpresaNewComponent,
    RptRecaudosComponent,
    RptTotalesRecaudosComponent,
    RptArqueoComponent,
    RptRetirosComponent,
    RptConsignacionesComponent,
    RptHeaderComponent,
    LoginComponent,
    UsersComponent,
    UsersNewComponent,
    UsersEditComponent,
    UsersDespachosComponent,
    UsersDespachosNewComponent,
    UsersChangePasswordComponent,
    RegisterComponent,
    AuthValidatorComponent,
    HomeComponent,
    DashboardComponent,
    RetirosComponent,
    RetirosNewComponent,
    BancosComponent,
    BancosNewComponent,
    MovimientosComponent,
    MovimientosNewComponent,
    DlgDescuentosComponent,
    VentasValidatorComponent,
    PropietariosValidatorComponent,
    ConductoresValidatorComponent,
    ConsultaRetirosComponent,
    ConsultaMovimientosComponent,
    ReportesPageComponent,
    ListReportesPageComponent,
    RutasComponent,
    RutasNewComponent,
    GruposComponent,
    GruposNewComponent,
    RetirosListComponent,
    RetirosListResponseComponent,
    MovimientosListComponent,
    MovimientosListResponseComponent,
    BlockTemplateComponent,
    UsersChangePasswordComponent,
    AdminDashboardComponent,
    UserDashboardComponent,
    NotificationsComponent,
    AccessDeniedComponent,
    UnauthorizedComponent,
    RolesComponent,
    RolesNewComponent,
    ListRolesComponent,
    EnviarMensajeComponent,
  ],
  entryComponents: [
    ClientesNewComponent,
    PropietariosNewComponent,
    ConductoresNewComponent,
    SociosNewComponent,
    MarcasNewComponent,
    TiposCuentasBancariasNewComponent,
    VehiculosNewComponent,
    VehiculosConductoresNewComponent,
    RecaudosNewComponent,
    VentasNewComponent,
    ProductosNewComponent,
    EmpresaNewComponent,
    BancosNewComponent,
    TarifasDescuentosNewComponent,
    DlgClientesComponent,
    DlgPropietariosComponent,
    DlgPlanillasComponent,
    DlgConductoresComponent,
    DlgSociosComponent,
    DlgRelevantesComponent,
    DlgRutasComponent,
    DlgVehiculosComponent,
    DlgRutasDespachosComponent,
    DlgSancionesComponent,
    DlgProductosComponent,
    DlgDescuentosComponent,
    DlgUsersComponent,
    DlgDespachosComponent,
    DlgRetirosComponent,
    UsersNewComponent,
    UsersEditComponent,
    UsersDespachosNewComponent,
    UsersChangePasswordComponent,
    RetirosNewComponent,
    MovimientosNewComponent,
    RutasNewComponent,
    GruposNewComponent,
    BlockTemplateComponent,
    DlgLugaresControlComponent,
    AccessDeniedComponent,
    RolesComponent,
    RolesNewComponent,
  ],

  imports: [
    FormsModule,
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoadingBarRouterModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
    MatInputModule,
    MatSliderModule,
    MatToolbarModule,
    MatIconModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxPrintModule,
    NgxChartsModule,
    QRCodeModule,
    NgxMaskModule.forRoot(maskConfig),
    TrendModule,
    DespachosModule,
    CooperativaModule,
    NgbTimepickerModule,
    PosPrintModule,
    AutocompleteLibModule,
    // MatKeyboardModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      closeButton: true,
      preventDuplicates: true,
      countDuplicates: true,
      progressBar: false,
    }),
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(EffectsArray),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    BlockUIModule.forRoot({
      template: BlockTemplateComponent,
    }),
  ],

  providers: [
    // PaginadorComponent,
    // PanelComponent,
    DatePipe,
    Title,
    NgbTooltipConfig,
    AuthorizatedGuard,

    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute
  ) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const title = "Colectivos Ciudad de Ipiales "; // + this.route.snapshot.firstChild.data['title'];
        this.titleService.setTitle(title);
      }
    });
  }
}
