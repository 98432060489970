import { Component, Input, OnInit } from "@angular/core";
import { ReportesDespachosService } from "../services/reportes-despachos.service";
import * as moment from "moment";

@Component({
  selector: "app-rpt-informe-dias-trabajados",
  templateUrl: "./rpt-informe-dias-trabajados.component.html",
  styleUrls: [],
})
export class RptInformeDiasTrabajadosComponent implements OnInit {
  @Input() data_rpt = [];
  @Input() tituloReporte = "";
  @Input() chkBus = false;
  @Input() _bus = "";
  FechaDesde = "";
  FechaHasta = "";
  loading: boolean = false;
  total_ss_parametro: number = 0;
  total_ss_conductor: number = 0;
  total_abono_ss: number = 0;

  constructor(private _serviceReportesDespachos: ReportesDespachosService) {
    this._serviceReportesDespachos.EventDatosInformeDiasTrabajados$.subscribe(
      async (resp: any) => {
        this.data_rpt = resp;
        this.get_Total_SS_Parametro();
        this.get_Total_SS_Conductor();
        this.get_Total_Abono_SS();
      }
    );

    this._serviceReportesDespachos.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportesDespachos.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });
  }

  ngOnInit() {}

  async get_Total_SS_Parametro() {
    this.total_ss_parametro = this.data_rpt
      .map((m) => m.ss_parametro)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_SS_Conductor() {
    this.total_ss_conductor = this.data_rpt
      .map((m) => m.valor_ss_conductor)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Abono_SS() {
    this.total_abono_ss = this.data_rpt
      .map((m) => parseFloat(m.valor_abono_ss))
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  exportAsXLSX() {
    this.loading = true;
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let filename: string = `INFORME_DIAS_TRABAJADOS_${strDate}.xlsx`;

    let periodo =
      "Desde: " +
      moment(this.FechaDesde).format("DD-MM-YYYY") +
      " - Hasta: " +
      moment(this.FechaHasta).format("DD-MM-YYYY");

    this.data_rpt = this.data_rpt.map((x, index) => ({
      item: index + 1,
      documento_propietario: parseInt(x.documento_propietario),
      bus: parseInt(x.bus),
      sucursal_contable: parseInt(x.sucursal_contable),
      propietario_nombres: x.propietario_nombres,
      total_abono_cooperativa: parseFloat(x.total_abono_cooperativa),
      total_aporte_cooperativa: parseFloat(x.total_aporte_cooperativa),
      total_item: parseFloat(x.total_item),
    }));

    this._serviceReportesDespachos.downloadFile_InformeMovimientosTotalCooperativa(
      this.data_rpt,
      filename,
      periodo
    );
  }
}
