//export const URL_API = "http://localhost:5000/api"; //Backend Developer
export const URL_API = "http://151.106.109.234:5000/api"; //Backend VPS Siris

//export const URL_API = "https://wacolectivos.siris.com.co/api"; //Backend VPS Siris con nombre

//export const URL_API_HTTPS = "https://151.106.109.234:5400/api"; //Backend VPS Siris Https
export const URL_API_HTTPS = "https://wacolectivos.siris.com.co/api"; //Backend VPS Siris Https con nombre

export const URL_API_NESTJS = "http://localhost:5200"; //Backend Nestjs Developer

export const URL_POS_PRINTER = "http://localhost:5600";
