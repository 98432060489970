import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { ClientesComponent } from "./pages/clientes/clientes.component";
import { EmpresaComponent } from "./pages/empresa/empresa.component";
import { VehiculosComponent } from "./pages/vehiculos/vehiculos.component";
import { MarcasComponent } from "./pages/marcas/marcas.component";
import { TarifasDescuentosComponent } from "./pages/tarifas-descuentos/tarifas-descuentos.component";
import { ProductosComponent } from "./pages/productos/productos.component";
import { VentasComponent } from "./pages/ventas/ventas.component";
import { UsersComponent } from "./auth/users.component";
import { RegisterComponent } from "./auth/register.component";
import { LoginComponent } from "./auth/login.component";
import { AuthorizatedGuard } from "./auth/authorizated-guard";
import { HomeComponent } from "./components/home/home.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { RetirosComponent } from "./pages/retiros/retiros.component";
import { ConsultaRetirosComponent } from "./pages/retiros/consulta-retiros.component";
import { PropietariosComponent } from "./pages/propietarios/propietarios.component";
import { RecaudosComponent } from "./pages/recaudos/recaudos.component";
import { ReportesPageComponent } from "./pages/reportes/reportes-page.component";
import { RutasComponent } from "./pages/rutas/rutas.component";
import { GruposComponent } from "./pages/grupos/grupos.component";
import { RetirosListComponent } from "./pages/retiros/retiros-list.component";
import { AdminDashboardComponent } from "./pages/dashboard/admin-dashboard.component";
import { UserDashboardComponent } from "./pages/dashboard/user-dashboard.component";
import { MovimientosComponent } from "./pages/movimientos/movimientos.component";
import { ConsultaMovimientosComponent } from "./pages/movimientos/consulta-movimientos.component";
import { BancosComponent } from "./pages/bancos/bancos.component";
import { TiposCuentasBancariasComponent } from "./pages/tipos_cuentas_bancarias/tipos_cuentas_bancarias.component";
import { ConductoresComponent } from "./pages/conductores/conductores.component";
import { VehiculosConductoresComponent } from "./pages/vehiculos-conductores/vehiculos-conductores.component";
import { UnauthorizedComponent } from "./auth/unauthorized/unauthorized.component";
import { RolesComponent } from "./auth/roles/roles.component";
import { SociosComponent } from "./pages/socios/socios.component";
import { UsersDespachosComponent } from "./pages/users-despachos/users-despachos.component";
import { EnviarMensajeComponent } from "./sms/enviar-mensaje/enviar-mensaje.component";
import { PosPrintComponent } from "./pos-print/pos-print/pos-print.component";
import { DashboardConsultasComponent } from "./despachos/dashboard/dashboard-consultas/dashboard-consultas.component";
import { PosPrinterConfigComponent } from "./pos-print/configuration/pos-printer-config.component";

const routes: Routes = [
  {
    path: "despachos",
    loadChildren: () =>
      import("./despachos/despachos.module").then((m) => m.DespachosModule),
  },
  {
    path: "cooperativa",
    loadChildren: () =>
      import("./cooperativa/cooperativa.module").then(
        (m) => m.CooperativaModule
      ),
  },
  {
    path: "clientes",
    component: ClientesComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "propietarios",
    component: PropietariosComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "conductores",
    component: ConductoresComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "socios",
    component: SociosComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "empresa",
    component: EmpresaComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "rutas",
    component: RutasComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "grupos",
    component: GruposComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "marcas",
    component: MarcasComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "tipos_cuentas_bancarias",
    component: TiposCuentasBancariasComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "bancos",
    component: BancosComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "vehiculos",
    component: VehiculosComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "vehiculos-conductores",
    component: VehiculosConductoresComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "users-despachos",
    component: UsersDespachosComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "tarifas",
    component: TarifasDescuentosComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "recaudos",
    component: RecaudosComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "producidos"],
    },
  },
  {
    path: "ventas",
    component: VentasComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "producidos"],
    },
  },
  {
    path: "productos",
    component: ProductosComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "producidos"],
    },
  },
  {
    path: "usuarios",
    component: UsersComponent,
    canActivate: [AuthorizatedGuard],
    canLoad: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "roles",
    component: RolesComponent,
    canActivate: [AuthorizatedGuard],
    canLoad: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "register",
    component: RegisterComponent,
    canActivate: [AuthorizatedGuard],

    data: { title: "Registro de Usuarios", role: ["superusuario", "admin"] },
  },
  {
    path: "login",
    component: LoginComponent,
    data: { title: "Inicio de Sesión" },
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "aplicacion"],
    },
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "admin"],
    },
  },
  {
    path: "retiros",
    component: RetirosComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "producidos"],
    },
  },
  {
    path: "retiros-periodo",
    component: RetirosListComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "producidos", "admin"],
    },
  },
  {
    path: "movimientos",
    component: MovimientosComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "producidos", "admin"],
    },
  },
  {
    path: "consulta-retiros",
    component: ConsultaRetirosComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "producidosqry", "admin"],
    },
  },
  {
    path: "consulta-movimientos",
    component: ConsultaMovimientosComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "producidosqry", "admin"],
    },
  },
  {
    path: "enviar-mensaje",
    component: EnviarMensajeComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario"],
    },
  },
  {
    path: "admin",
    component: AdminDashboardComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario"],
    },
  },
  {
    path: "user",
    component: UserDashboardComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario"],
    },
  },
  {
    path: "post-print",
    component: PosPrintComponent,
  },
  {
    path: "dashboard-despachos",
    component: DashboardConsultasComponent,
  },
  {
    path: "pos-print-config",
    component: PosPrinterConfigComponent,
  },

  {
    path: "reportes-recaudos",
    component: ReportesPageComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      role: ["superusuario", "producidosqry"],
    },
  },
  {
    path: "unauthorized",
    component: UnauthorizedComponent,
  },
  {
    path: "",
    canLoad: [AuthorizatedGuard],
    loadChildren: () =>
      import("./despachos/despachos.module").then((m) => m.DespachosModule),
  },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
