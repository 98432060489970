import { Component, Input, OnInit } from "@angular/core";
import { ReportesDespachosService } from "../services/reportes-despachos.service";
import * as moment from "moment";

@Component({
  selector: "app-rpt-informe-planillas",
  templateUrl: "./rpt-informe-planillas.component.html",
  styleUrls: [],
})
export class RptInformePlanillasComponent implements OnInit {
  @Input() data_rpt = [];
  @Input() tituloReporte = "";
  @Input() chkBus = false;
  @Input() _bus = "";
  @Input() despacho_liquidacion = "";
  @Input() _cerrada;
  FechaDesde = "";
  FechaHasta = "";
  loading: boolean = false;
  total_retardos: number = 0;
  total_contingencia: number = 0;
  total_bioseguridad: number = 0;
  total_gps: number = 0;
  total_vias: number = 0;
  total_ss_conductor: number = 0;
  total_planilla: number = 0;
  total_general: number = 0;
  total_relevante: number = 0;
  total_abono_ss: number = 0;
  total_abono_ss_relevante: number = 0;
  total_minutos: number = 0;
  total_ahorro_propietario: number = 0;
  total_abono_credito: number = 0;
  total_sanciones: number = 0;
  total_producido: number = 0;
  total_otros: number = 0;

  constructor(private _serviceReportesDespachos: ReportesDespachosService) {
    this._serviceReportesDespachos.EventDatosInformePlanillas$.subscribe(
      async (resp: any) => {
        this.data_rpt = resp;
        /* this.data_rpt.forEach((item) => {
          item.numero = parseInt(item.numero);
        }); */
        this.get_Total_Minutos();
        this.get_Total_Ahorro_Propietario();
        this.get_Total_Abono_SS();
        this.get_Total_Abono_SS_Relevante();
        //this.get_Total_Producido();
        this.get_Total_Otros();
        this.get_Total_Sanciones();
        this.get_Total_Relevante();
        this.get_Total_Retardos();
        this.get_Total_Contingencia();
        this.get_Total_Bioseguridad();
        this.get_Total_GPS();
        this.get_Total_Vias();
        this.get_Total_SS_Conductor();
        this.get_Total_Planilla();
        this.get_Total_General();
      }
    );

    this._serviceReportesDespachos.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportesDespachos.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });
  }

  ngOnInit() {}

  async get_Total_Minutos() {
    this.total_minutos = this.data_rpt
      .map((m) => m.minutos)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Ahorro_Propietario() {
    this.total_ahorro_propietario = this.data_rpt
      .map((m) => m.valor_ahorro_propietario)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Producido() {
    this.total_producido = this.data_rpt
      .map((m) => m.producido)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Otros() {
    this.total_otros = this.data_rpt
      .map((m) => m.otros)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Sanciones() {
    this.total_sanciones = this.data_rpt
      .map((m) => m.total_sanciones)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Abono_SS() {
    this.total_abono_ss = this.data_rpt
      .map((m) => m.valor_abono_ss)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Abono_SS_Relevante() {
    this.total_abono_ss_relevante = this.data_rpt
      .map((m) => m.valor_abono_ss_relevante)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Relevante() {
    this.total_relevante = this.data_rpt
      .map((m) => m.valor_relevante)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Retardos() {
    this.total_retardos = this.data_rpt
      .map((m) => m.retardos)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Contingencia() {
    this.total_contingencia = this.data_rpt
      .map((m) => m.valor_fondo_contingencia)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Bioseguridad() {
    this.total_bioseguridad = this.data_rpt
      .map((m) => m.valor_bioseguridad)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_GPS() {
    this.total_gps = this.data_rpt
      .map((m) => m.valor_gps)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Vias() {
    this.total_vias = this.data_rpt
      .map((m) => m.valor_vias)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_SS_Conductor() {
    this.total_ss_conductor = this.data_rpt
      .map((m) => m.valor_ss_conductor)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Planilla() {
    this.total_planilla = this.data_rpt
      .map((m) => m.valor_planilla)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_General() {
    this.total_general = this.data_rpt
      .map((m) => parseFloat(m.total_item))
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  exportAsXLSX() {
    this.loading = true;
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let filename: string = `INFORME_PLANILLAS_${strDate}.xlsx`;

    let periodo =
      "Desde: " +
      moment(this.FechaDesde).format("DD-MM-YYYY") +
      " - Hasta: " +
      moment(this.FechaHasta).format("DD-MM-YYYY") +
      " | Despacho Liquidación: " +
      this.despacho_liquidacion;

    this.data_rpt = this.data_rpt.map((x, index) => ({
      item: index + 1,
      despacho_nombre: x.despacho_nombre,
      numero: parseInt(x.numero),
      fecha_planilla: x.fecha_planilla,
      fecha_liquidacion: x.fecha_liquidacion,
      planilla_id: x.planilla_id,
      valor_ahorro_propietario: parseFloat(x.valor_ahorro_propietario),
      producido: parseFloat(x.producido),
      valor_abono_ss: parseFloat(x.valor_abono_ss),
      valor_abono_ss_relevante: parseFloat(x.valor_abono_ss_relevante),
      valor_relevante: parseFloat(x.valor_relevante),
      abono_credito_cooperativa: parseFloat(x.abono_credito_cooperativa),
      total_sanciones: parseFloat(x.total_sanciones),
      retardos: parseFloat(x.retardos),
      valor_fondo_contingencia: parseFloat(x.valor_fondo_contingencia),
      valor_bioseguridad: parseFloat(x.valor_bioseguridad),
      valor_gps: parseFloat(x.valor_gps),
      valor_vias: parseFloat(x.valor_vias),
      valor_ss_conductor: parseFloat(x.valor_ss_conductor),
      valor_planilla: parseFloat(x.valor_planilla),
      total_item: parseFloat(x.total_item),
    }));

    this._serviceReportesDespachos.downloadFile_InformePlanillas(
      this.data_rpt,
      filename,
      periodo
    );
  }
}
