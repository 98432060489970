import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { URL_API } from "src/app/config/config";

@Injectable({
  providedIn: "root",
})
export class LogirastreosService {
  EventDatosLogirasteos$ = new EventEmitter<any>();
  FechaDesde$ = new EventEmitter<any>();
  FechaHasta$ = new EventEmitter<any>();
  bus$ = new EventEmitter<String>();
  placa$ = new EventEmitter<String>();
  url = URL_API;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(private http: HttpClient) {}

  get_planillabyplacaAndFecha(model: any) {
    return this.http.post(
      `${this.url}/logirastreos/consultabyplacafecha`,
      model
    );
  }

  get_planillabyplacaAndPeriodo(model: any) {
    console.log("model", model);
    return this.http.post(
      `${this.url}/logirastreos/consultabyplacaperiodo`,
      model
    );
  }
}
